import { Controller } from "@hotwired/stimulus"
import loadCitiesInRegionArea from "../../src/shared/helpers/load_cities_in_region_area";

// Connects to data-controller="catalog--cities-in-region-areas"
export default class extends Controller {
  connect() {
    this.currentCity = Sti.currentCity;

    this.regionAreasContainer = this.element.querySelector('.left-column');
    this.citiesContainer = this.element.querySelector('.right-column-items');
  }

  switchRegionArea(e) {
    e.preventDefault()

    const button = e.currentTarget;
    if (button.classList.contains('active')) return;

    this.regionAreasContainer.querySelectorAll('.active').forEach((el) => el.classList.remove('active'));
    button.classList.add('active');

    this.citiesContainer.innerHTML = '';
    const regionArea = button.dataset.regionArea;
    loadCitiesInRegionArea(regionArea, (response) => {
      this.citiesContainer.innerHTML = this.renderCityLinks(response.cities);
    })
  }

  renderCityLinks(cities) {
    return cities.map(city => {
      const pathname = this.replaceCityInCurrentPathname(city);
      const url = pathname + location.search;

      return this.renderColumnItem(city.name, url);
    }).join('');
  }

  replaceCityInCurrentPathname(city) {
    let pathname = location.pathname;

    if (this.currentCity) {
      const currentCitySlug = this.currentCity.slug;
      pathname = pathname.replace(`/${currentCitySlug}`, '');
    }

    return `${pathname}/${city.slug}`;
  }

  renderColumnItem(name, path) {
    return `<li><a href="${path}"><span>${name}</span></a></li>`;
  }
}
