import maskPhone from '../../shared/helpers/mask_phone';

class Sti.PhonesFields
  constructor: (@target) ->
    @target = $ @target
    @target.find('.profile-form-phone').each (i, phone)=>
      new Sti.PhoneFields $(phone), this
    @addBtn = @target.find '.add'
    @binds()

  binds: ->
    @addBtn.on 'click', @addClicked

  addClicked: (event) =>
    event.preventDefault()

    newFields = @newFields(@addBtn.data 'name')
    @addBtn.before newFields

    @checkAddBtnPresence()

  checkAddBtnPresence: =>
    if @target.find('.profile-form-phone').length >= 10
      @addBtn.addClass('-hidden')
    else
      @addBtn.removeClass('-hidden')
    false

  newFields: (name) =>
    name = name || "profile[phones_attributes]"
    id = (new Date()).getTime()
    newFields = $ """
      <div class="profile-form-phone">
        <input class="phone" type="text" value="" name="#{name}[#{id}][plain]"><input class="phone" placeholder="имя" type="text" value="" name="#{name}[#{id}][caption]"><a class="destroy" data-method="stub" href="#" title="Удалить">✕</a>
      </div>
    """
    new Sti.PhoneFields newFields, this
    newFields

class Sti.PhoneFields
  constructor: (@target, @form) ->
    input = @target[0].querySelector('[name$="[plain]"]')
    maskPhone(input)

    @destroyBtn = @target.find '.destroy'
    @error = @target.find '.error'
    @binds()

  binds: =>
    @destroyBtn.on 'click', @destroyBtnClicked

  destroyBtnClicked: (event) =>
    event.preventDefault()
    # event.stopPropagation()

    method = @destroyBtn.data('method')
    if method == 'stub'
      @remove()
    else
      $.ajax
        url: @destroyBtn.attr('href')
        type: 'delete'
        success: @remove
        dataType: 'json'
        error: (xhr) =>
          if xhr.status == 422
            @error.text 'Этот телефон нельзя удалить, потому что в одном или нескольких объявлениях это единственный телефон.'
          else
            @error.text 'Не удалось удалить телефон.'
    false

  remove: =>
    @target.fadeOut =>
      @target.next('input[type="hidden"]').remove()
      @target.remove()
      @form.checkAddBtnPresence()
