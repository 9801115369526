import maskEmailOrPhone from '../shared/helpers/mask_email_or_phone';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#auth_login')) {
    const emailOrPhoneInput = document.querySelector('#auth_login_email_or_phone');
    maskEmailOrPhone(emailOrPhoneInput);
  }

  if (document.querySelector('#auth_registration')) {
    const emailOrPhoneInput = document.querySelector('#auth_registration_email_or_phone');
    maskEmailOrPhone(emailOrPhoneInput);
  }

  if (document.querySelector('#auth_new_password_recovery')) {
    const emailOrPhoneInput = document.querySelector('#auth_new_password_recovery_email_or_phone');
    maskEmailOrPhone(emailOrPhoneInput);
  }
});

