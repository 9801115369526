import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form--limited-length"
export default class extends Controller {
  static targets = ['input']
  static values = { maxLength: Number }

  connect() {
    // const input = this.element.querySelector('textarea');

    if (this.inputTarget) {
      this.inputTarget.addEventListener('input', (_) => this.update());
    }
  }

  update(e) {
    const input = this.inputTarget;

    if (this.maxLengthValue) {
      const current = input.value.length;
      let text = '';
      const controlGroupEl = input.closest('.control-group');
      let errorEl = input.parentNode.querySelector('.error-message');

      if (!errorEl) {
        errorEl = document.createElement('p');
        errorEl.classList.add('help-block');
        errorEl.classList.add('error-message');
        input.after(errorEl);
      }

      if (this.maxLengthValue >= current) {
        controlGroupEl.classList.remove('error');

        if (this.maxLengthValue * (2 / 3) < current) {
          let left = this.maxLengthValue - current;
          text = `Осталось символов для этого поля — ${left}`;
        }
      } else {
        text = `Превышена максимальная длина этого поля — ${this.maxLengthValue}, сейчас — ${current}`;
        controlGroupEl.classList.add('error');
      }

      errorEl.innerText = text;
    }
  }
}


// Sti.LimitedLengthField = function(input) {
//   function displayLeftLetters() {
//     var current = input.value.length;
//     var text = '';

//     if (max >= current) {
//       controlGroupEl.classList.remove('error');

//       if (max * (2/3) < current) {
//         var left = max - current;
//         text = `Осталось символов для этого поля — ${left}`;
//       }
//     } else {
//       text = `Превышена максимальная длина этого поля — ${max}, сейчас — ${current}`;
//       controlGroupEl.classList.add('error');
//     }

//     errorEl.innerText = text;
//   }

//   var max = input.dataset.maxLength;
//   var controlGroupEl;
//   var errorEl;

//   if (max) {
//     controlGroupEl = input.closest('.control-group');
//     errorEl = input.nextElementSibling;

//     if (!errorEl || !errorEl.classList.contains('error-message')) {
//       errorEl = document.createElement('p');
//       errorEl.classList.add('help-block');
//       errorEl.classList.add('error-message');
//       input.after(errorEl);
//     }

//     input.addEventListener('keyup', displayLeftLetters)
//   }
// }
