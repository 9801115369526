import Data from './data.js.erb';

import numberToCurrency from './helpers/number_to_currency';
import authenticityToken from './helpers/authenticity_token';
import getCurrentRegion from './helpers/get_current_region';
import getRegionBySubdomain from './helpers/get_region_by_subdomain';
import byPosition from './helpers/by_position';
import renderTemplate from './helpers/render_template';
import renderTemplateHTML from './helpers/render_template_html';
import htmlToElems from './helpers/html_to_elems';
import isElementInViewport from './helpers/is_element_in_viewport';

class Helpers {
  static numberToCurrency = numberToCurrency;
  static authenticityToken = authenticityToken;
  static currentRegion = getCurrentRegion;
  static byPosition = byPosition;
  static regionBySubdomain = getRegionBySubdomain;
  static renderTemplate = renderTemplate;
  static renderTemplateHTML = renderTemplateHTML;
  static htmlToElems = htmlToElems;
  static isElementInViewport = isElementInViewport;

  static isMobileVersion = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    return width <= 767;
  }
}

export default Helpers;
