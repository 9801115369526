export default function numberToCurrency(number, options) {
  var fixed, numberWithUnit, splitted;

  if (Number.isNaN(number) || number === undefined) {
    return '–';
  }

  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  options || (options = {});
  if (!options.hasOwnProperty('unit')) {
    options['unit'] = 'руб.';
  }
  if (!options.hasOwnProperty('precision')) {
    options['precision'] = 2;
  }

  fixed = number.toFixed(options['precision']).replace('.', ',');
  splitted = fixed.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  numberWithUnit = [splitted, options['unit']].join(' ').replaceAll(' ', '&nbsp;');

  return numberWithUnit;
}
