import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--category-switcher"
export default class extends Controller {
  static targets = ["list"]
  static values = {
    categories: Array,
    section: String,
    subsectionSlug: String,
  }
  static sectionSlugs = {
    "teches": "arenda",
    "bids": "zayavki",
    "services": "uslugi",
    "sells": "prodazha",
    "spares": "zapchasti",
    "repairs": "remont"
  }

  connect() {
    this.currentCity = Sti.currentCity

    this.listTarget.innerHTML = this.renderItems().join("");

    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.handleClickOutside)
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      // Click was outside of this controller's element, hide the dropdown
      this.hide()
    }
  }

  toggle(event) {
    event.preventDefault();
    this.element.classList.toggle("open");
  }

  hide() {
    this.element.classList.remove("open");
  }

  renderItems() {
    return this.categoriesValue.map(category => {
      return this.renderItem(category)
    })
  }

  renderItem(category) {
    const link = this.renderLink(category)
    const a = `<a href="${link}">${category.title}</a>`
    const liClasses = this.isCurrentPage(link) ? "active" : ""
    return `<li class="${liClasses}">${a}</li>`
  }

  renderLink(category) {
    let parts = ['/' + this.constructor.sectionSlugs[this.sectionValue]];

    switch (this.sectionValue) {
      case "teches":
        parts.push(category.slug);
        if (this.currentCity && !this.currentCity.isCapital) {
          parts.push(this.currentCity.slug);
        }
        break;
      case "bids":
        if (this.subsectionSlugValue) {
          parts.push(this.subsectionSlugValue);
        }
        if (category.slug) {
          parts.push(category.slug);
        }
        break;
      case "services":
        if (category.slug) {
          parts.push(category.slug);
        }
        if (this.currentCity && !this.currentCity.isCapital) {
          parts.push(this.currentCity.slug);
        }
        break;
      case "sells":
        if (category.slug) {
          parts.push(category.slug);
        }
        if (this.filter) {
          parts.push(this.filter);
        }
        break;
      case "spares":
        if (category.slug) {
          parts.push(category.slug);
        }
        if (this.filter) {
          parts.push(this.filter);
        }
        break;
      case "repairs":
        if (category.slug) {
          parts.push(category.slug);
        }
        if (this.currentCity && !this.currentCity.isCapital) {
          parts.push(this.currentCity.slug);
        }
        break;
    }

    return parts.join('/');
  }

  isCurrentPage(link) {
    return window.location.pathname + window.location.search === link
  }
}
