import ShowMoreItemsComponent from '../../shared/show_more_items_component'
import PostContacts from './contacts.js'
import PostPhotos from './photos.js'
import Map from './map.js'

export default class PostCard {
  constructor(el) {
    this.el = el

    const photosEl = this.el.querySelector('.post-photos')
    if (photosEl) { new PostPhotos(photosEl) }

    const mapEl = this.el.querySelector('.map')
    if (mapEl) { new Map(mapEl) }

    const placementNumber = el.closest('[data-placement-number]').dataset['placementNumber'];

    const contactsEl = this.el.querySelector('.contact-block')
    if (contactsEl) { new PostContacts(contactsEl, placementNumber) }

    new ShowMoreItemsComponent(this.el)
    this.readMoreBtn = this.el.querySelector('.read-more')

    this.binds()
  }

  binds() {
    if (this.readMoreBtn) {
      this.readMoreBtn.addEventListener('click', (e) => this.activateReadMore(e))
    }
  }

  activateReadMore(e) {
    e.preventDefault()

    const richText = e.target.closest('.card-content')
    richText.classList.remove('cropped')
    richText.classList.add('full')

    return false
  }
}
