const Phone = {
  defaultMask: "8 DDD DDD-DD-DD",
  masks: [
    "8 DDD DDD-DD-DD",
    "+7 DDD DDD-DD-DD"
  ],

  applyMask: function (string) {
    if(string === undefined || string === null || string === '') { return '' }
    if(string === '+') { return '+' }

    const maskedValuesWithNumbers = this.masks.map((mask) => {
      return this.applyMaskToValue(mask, string)
    })

    if (maskedValuesWithNumbers.length === 0) { return '' }

    const longestMaskedValue = maskedValuesWithNumbers.sort((a, b) => b[1] - a[1]).map((a) => a[0])
    const longestMaskedValueDigits = this.stripToDigits(longestMaskedValue[0]);

    if (longestMaskedValueDigits.length === 11) {
      return this.normalizeAndApplyDefaultMask(longestMaskedValueDigits)
    } else {
      return longestMaskedValue[0];
    }
  },

  normalizeAndApplyDefaultMask: function (digits) {
    if (digits.length !== 11) {
      return ''
    }

    const normalizedPhone = '8' + digits.slice(1);
    return this.applyMaskToValue(this.defaultMask, normalizedPhone)[0];
  },

  applyMaskToValue: function (mask, value) {
    // convert the value to a string, in case it isn't already
    // and remove non-digit characters from the value
    let valueDigits = this.stripToDigits(value);
    let maskedValue = '';
    let valueIndex = 0

    // apply digits from the value to the mask
    for (let maskIndex = 0; maskIndex < mask.length && valueIndex < valueDigits.length; maskIndex++) {
      //
      if (mask[maskIndex] === 'D' && valueIndex < valueDigits.length ||
          mask[maskIndex] === valueDigits[valueIndex]) {
        // replace 'D' with the next digit from the value
        maskedValue += valueDigits[valueIndex];
        valueIndex++;
      } else  {
        // just copy the non-'D' character from the mask
        maskedValue += mask[maskIndex];
      }
    }
    // Hack to encrease valueIndex if the first digit of the mask is '+'
    if (mask[0] === '+' && value[0] === '+') { valueIndex++ }

    return [maskedValue, valueIndex];
  },

  stripToDigits: function (string) {
    return string.toString().replace(/\D/g, '');
  },
}

export default Phone;
