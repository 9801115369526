
const enRuTranscribeTable = {"Q":"Й","W":"Ц","E":"У","R":"К","T":"Е","Y":"Н","U":"Г","I":"Ш","O":"Щ","P":"З","[":"х","]":"ъ","A":"Ф","S":"Ы","D":"В","F":"А","G":"П","H":"Р","J":"О","K":"Л","L":"Д",";":"ж","'":"э","Z":"Я","X":"Ч","C":"С","V":"М","B":"И","N":"Т","M":"Ь",",":"б",".":"ю","/":".","q":"й","w":"ц","e":"у","r":"к","t":"е","y":"н","u":"г","i":"ш","o":"щ","p":"з","a":"ф","s":"ы","d":"в","f":"а","g":"п","h":"р","j":"о","k":"л","l":"д","z":"я","x":"ч","c":"с","v":"м","b":"и","n":"т","m":"ь"};

export default function enRuTranscribe(str) {
  if (str && str.length) {
    return str.split('').map((v) => enRuTranscribeTable[v] || v ).join('');
  } else {
    return '';
  }
}
