module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  var idPrefix=namePrefix.replaceAll('[', '_' ).replaceAll(']', '' ) 
    ; __append("\n\n  <div class=\"control-group optional eav-numeric-control ")
    ; __append(escapeFn( attr.important ? '' : 'd-hidden' ))
    ; __append("\">\n    <label for=\"")
    ; __append(escapeFn( idPrefix ))
    ; __append("_value\">\n      ")
    ; __append(escapeFn( attr.title ))
    ; __append("\n        ")
    ;  if (attr.measure.length) { 
    ; __append(", ")
    ; __append(escapeFn( attr.measure ))
    ; __append("\n            ")
    ;  } 
    ; __append("\n    </label>\n\n    <div class=\"controls\">\n      <input type=\"hidden\" name=\"")
    ; __append(escapeFn( namePrefix + '[attr_id]' ))
    ; __append("\" value=\"")
    ; __append(escapeFn( attr.id ))
    ; __append("\">\n      <input type=\"hidden\" name=\"")
    ; __append(escapeFn( namePrefix + '[type]' ))
    ; __append("\" value=\"")
    ; __append(escapeFn( attr.value_class_name ))
    ; __append("\">\n      <input type=\"number\" id=\"")
    ; __append(escapeFn( idPrefix ))
    ; __append("_value\" name=\"")
    ; __append(escapeFn( namePrefix + '[value]' ))
    ; __append("\" min=\"")
    ; __append(escapeFn( attr.min))
    ; __append("\"\n        max=\"")
    ; __append(escapeFn( attr.max ))
    ; __append("\" step=\"")
    ; __append(escapeFn( attr.step ))
    ; __append("\">\n      <p class=\"help-block field-caption\">\n        oт ")
    ; __append(escapeFn( attr.min ))
    ; __append(" до ")
    ; __append(escapeFn( attr.max ))
    ; __append("\n      </p>\n    </div>\n  </div>\n")
  }
  return __output;

}