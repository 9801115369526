import { Controller } from "@hotwired/stimulus"
import renderTemplateHTML from "../src/shared/helpers/render_template_html";

// Connects to data-controller="contact-request"
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (e) => this.showModal(e));

    const catalogCategoryDataEl = this.element.closest('[data-catalog-category-id]')
    if (catalogCategoryDataEl) { this.catalogCategoryId = catalogCategoryDataEl.dataset.catalogCategoryId }

    this.placementNumber = this.element.closest('[data-placement-number]').dataset.placementNumber
    this.modal = new ContactRequestModal()
  }

  showModal(e) {
    e.preventDefault()

    this.modal.show()

    this.loadForm(this.placementNumber, this.catalogCategoryId).then((body) => {
      this.modal.updateBody(body)
    })
  }

  loadForm(placementNumber, catalogCategoryId) {
    const url = new URL('/contact_requests/new', window.location.origin)
    if (placementNumber) { url.searchParams.append('placement_number', placementNumber) }
    if (catalogCategoryId) { url.searchParams.append('catalog_category_id', catalogCategoryId) }

    return fetch(url).then((response) => response.text())
  }
}

class ContactRequestModal {
  constructor() {
    this.element = renderTemplateHTML('general/modal', { title: "Запрос обратного звонка", body: "Загрузка..." })[0]
    this.body = this.element.querySelector('.modal-body')
  }

  show() {
    $(this.element).modal('show');
  }

  updateBody(body) {
    this.body.innerHTML = body || '';

    if (this.body.querySelector('form')) {
      this.body.querySelector('form').addEventListener('ajax:complete', (event) => {
        const [xhr, status] = event.detail;
        this.updateBody(xhr.response)
      });
    }
  }
}
