module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"modal\" tabindex=\"-1\">\n  <div class=\"modal-header\">\n    <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">×</button>\n    <h3>Назначение менеджера</h3>\n  </div>\n\n  <div class=\"modal-body\">\n    <ul style=\"list-style-type: none\">\n      ")
    ;  managers.forEach(function(manager) { 
    ; __append("\n        <li>\n          ")
    ;  if (client.free) { 
    ; __append("\n            <a href=\"/admin/clients?user_id=")
    ; __append(escapeFn( client.userId ))
    ; __append("&manager_id=")
    ; __append(escapeFn( manager.id ))
    ; __append("\" class=\"upsert\" data-method=\"post\">")
    ; __append(escapeFn( manager.title ))
    ; __append("</a>\n          ")
    ;  } else { 
    ; __append("\n            <a href=\"/admin/clients/")
    ; __append(escapeFn( client.userId ))
    ; __append("?manager_id=")
    ; __append(escapeFn( manager.id ))
    ; __append("\" class=\"upsert\" data-method=\"put\">")
    ; __append(escapeFn( manager.title ))
    ; __append("</a>\n          ")
    ;  } 
    ; __append("\n        </li>\n      ")
    ;  }) 
    ; __append("\n    </ul>\n  </div>\n</div>\n")
  }
  return __output;

}