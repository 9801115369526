export default class ShowMoreItemsComponent {
  defaultsOpts = { comma: true }

  constructor(el, opts = {}) {
    this.el = el;
    this.opts = opts;
    this.options = Object.assign(this.defaultsOpts, opts);

    this.init();
  }

  init() {
    this.el.querySelectorAll('[role="show_more_items"]').forEach((el) => {
      el.addEventListener('click', (e) => this.show(e))
    })
  }

  show(e) {
    const btn = e.currentTarget;
    const nextEl = btn.nextElementSibling;
    nextEl.classList.remove('-show_more_items-covered');

    if (this.options.comma) {
      const prevEl = btn.previousSibling;
      if (prevEl && prevEl.nodeType == Node.TEXT_NODE) {
        prevEl.textContent = prevEl.textContent.replace(/\s*$/, '');
        btn.insertAdjacentText('afterend', ', ');
      }
    }

    btn.remove()
  }
}
