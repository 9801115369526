import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer"
import createMessagesSubscription from "channels/admin/messages_channel"

export default class extends Controller {
  static values = { id: Number }
  static targets = ["messages", "form", "button", "input"]
  static outlets = ["admin--chat-message"]

  connect() {
    this.subscription = this.createSubscription()
  }

  formSubmiting(event) {
    this.disableButton()
  }

  formSubmited(event) {
    this.enableButton()
    this.resetInput()
  }

  disableButton() {
    this.buttonTarget.disabled = true;
  }
  enableButton() {
    this.buttonTarget.disabled = false;
  }
  resetInput() {
    this.inputTarget.value = "";
  }

  createSubscription() {
    const ref = this;
    const accountId = this.idValue;

    return createMessagesSubscription(accountId, (data) => {
      switch (data.event) {
        case 'create':
          ref.addMessage(data);
          break;
        case 'update':
          ref.updateMessage(data);
          break;
        default:
          console.log(`Unknown event: ${data.event}`);
      }
    })
  }

  updateMessage(data) {
    const id = data.message.id;
    const outlet = this.adminChatMessageOutlets.find((outlet) => { return outlet.idValue === id });
    outlet.replace(data);
  }

  addMessage(data) {
    const message = data.message;
    const html = data.html;

    this.messagesTarget.insertAdjacentHTML("afterbegin", html);

    if (message.incoming) {
      this.subscription.updateStatus(message.id, 'delivered')
    }
  }
}
