class Sti.LimitedCheckboxes
  constructor: (@el, @messages) ->
    @el = $(@el)
    @displayEl = @el.find('#limit_message')
    @data = {}
    @set @displayEl.data('limit'), skipHitMessage: true

  set: (limit, opts={}) =>
    Object.assign(@data, limit)
    @update(opts)

  update: (opts={}) =>
    @updateInfoText()

    if !opts['skipHitMessage'] and @isLimitHit()
      @showHitMessage()

  updateInfoText: =>
    msg = @messages.info(@data)
    @displayEl.text msg

    if @data.reached > @data.overall * 0.33
      @displayEl.show()

  checkLimitsOnChange: (selector) =>
    @el.on 'change', selector, (e) =>
      reached = @el.find(selector).filter(':checked').length

      @set({ reached: reached }, { skipHitMessage: !e.target.checked })

      if @isLimitCrossed(reached) && e.target.checked
        e.target.checked = false
        @set({ reached: (reached - 1) }, { skipHitMessage: true })

  showHitMessage: =>
    msg = @messages.hitLimit(@data)
    window.delay 10, => alert(msg)

  isLimitHit: =>
    @data.reached >= @data.overall

  isLimitCrossed: (reached) =>
    reached > @data.overall
