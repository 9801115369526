import MobileModal from "./mobile/modal";
import Data from '../shared/data.js';
import Helpers from '../shared/helpers.js';
import renderTemplateHTML from '../shared/helpers/render_template_html';

let mobileChanger, desktopChanger;

document.querySelectorAll(".change-region").forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();

    if (Helpers.isMobileVersion()) {
      mobileChanger ||= new MobileRegionChanger();
      mobileChanger.show()
    } else {
      desktopChanger ||= new DesktopRegionChanger();
      desktopChanger.show()
    }
  })
})

class DesktopRegionChanger {
  constructor() {
    this.container = $(Helpers.renderTemplate('shared/region_changer/show', {
      currentRegion: Helpers.currentRegion(),
      districts: Data.federalDistricts
    }));

    this.container.appendTo('body')

    this.binds()
  }

  binds() {
    const districts = this.container[0].querySelectorAll('#districts a');
    districts.forEach((el) => {
      el.addEventListener('click', (event) => {
        const selected = event.currentTarget;

        if (!selected.classList.contains('active')) {
          const districtRegionsSelector = selected.getAttribute('href');
          const activeEls = this.container[0].querySelectorAll('#districts .active, #district_regions .active');
          activeEls.forEach((el) => el.classList.remove('active'));

          this.container[0].querySelector(districtRegionsSelector).classList.add('active');
          selected.classList.add('active');
        }
      })
    })
  }

  show() {
    this.container.modal('show')
  }
}

class MobileRegionChanger {
  constructor() {
    this.container = renderTemplateHTML('shared/mobile_region_changer/show', {
      currentRegion: Helpers.currentRegion(),
      districts: Data.federalDistricts
    })[0];

    document.querySelector('body').appendChild(this.container);
    this.modal = new MobileModal(this.container)
  }

  show() {
    this.modal.show()
  }
}
