class Sti.PhotoFields
  constructor: (options) ->
    @options = options
    @container = if options.container.jquery
      options.container[0]
    else
      options.container
    @imageableName = options.imageableName

    @addBtn = @container.querySelector '.add-photo'
    @fileInput = @container.querySelector 'input[type=file]'

    @binds()

  finishUploading: =>
    @addBtn.classList.remove('-uploading')

  startUploading: =>
    @addBtn.classList.add('-uploading')

  insertPhotoField: (photo) =>
    newField = @renderPhotoField(photo)
    @addBtn.insertAdjacentHTML('beforebegin', newField);

  renderPhotoField: (photo) =>
    """
      <div class="photo-field" id="photo_#{photo.id}">
        <input type="hidden" name="#{@imageableName}[photo_ids][]" value=#{photo.id}>
        <img src=#{photo.file.thumb.url}>
        <a class="btn btn-danger btn-mini remove" href="/images/#{photo.id}">
          <i class="icon-trash icon-white"></i>
        </a>
      </div>
    """

  binds: ->
    @fileUploadBind()

    Sti.Lib.on '.remove', 'click', @removeBtnClicked, @container

    s = Sti.Lib.sorting @container, 'images',
      touch: 'imageable',
      placeholderClass: 'photo-field'
      orientation: 'horizontal',
      # placeholder: 'photo-field-placeholder',
      items: '.photo-field',
      handle: 'img'


  fileUploadBind: =>
    @photoType = @options.photoType
    formData = { type: @photoType }
    if @container.dataset['imageable_id']
      formData["image[imageable_id]"] = @container.dataset['imageable_id']
      formData["image[imageable_type]"] = @container.dataset['imageable_type']

    Sti.Lib.fileUpload @fileInput,
      dataType: 'json'
      url: '/images'
      method: 'POST'
      formData: formData
      start: () =>
        @startUploading()
      success: (photo) =>
        @insertPhotoField photo
        @finishUploading()
      error: (errorMessage) =>
        alert(errorMessage)
        @finishUploading()

  removeBtnClicked: (event) ->
    event.preventDefault()
    removeBtn = @

    photoField = removeBtn.parentNode
    url = removeBtn.getAttribute 'href'

    formData = new FormData()
    formData.append('authenticity_token', Sti.Helpers.authenticityToken());

    fetch(url, { method: 'delete', body: formData })
      .then((r) -> r.json)
      .then(() -> photoField.remove())
