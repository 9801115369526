Sti.LimitedLengthField = function(input) {
  function displayLeftLetters() {
    var current = input.value.length;
    var text = '';

    if (max >= current) {
      controlGroupEl.classList.remove('error');

      if (max * (2/3) < current) {
        var left = max - current;
        text = `Осталось символов для этого поля — ${left}`;
      }
    } else {
      text = `Превышена максимальная длина этого поля — ${max}, сейчас — ${current}`;
      controlGroupEl.classList.add('error');
    }

    errorEl.innerText = text;
  }

  var max = input.dataset.maxLength;
  var controlGroupEl;
  var errorEl;

  if (max) {
    controlGroupEl = input.closest('.control-group');
    errorEl = input.nextElementSibling;

    if (!errorEl || !errorEl.classList.contains('error-message')) {
      errorEl = document.createElement('p');
      errorEl.classList.add('help-block');
      errorEl.classList.add('error-message');
      input.after(errorEl);
    }

    input.addEventListener('keyup', displayLeftLetters)
  }
}
