import { Controller } from "@hotwired/stimulus"

import fetchWithCsrf from "../../src/shared/helpers/fetch_json_with_csrf"

// Connects to data-controller="admin--bid"
export default class extends Controller {
  static values = { bidId: Number }

  connect() {
  }

  pause() {
    fetchWithCsrf(`/admin/bids/${this.bidIdValue}/pause`, { method: 'PATCH' })
      .then(response => {
        if (response.done) {
          console.log("bid paused")
        } else {
          console.log("bid un-paused")
        }
      })
  }
}
