// import Phone from '../../shared/phone';
import maskPhone from '../../shared/helpers/mask_phone';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector('#user_profile_form');
  if (container) {
    new UserProfileForm(container);
  }
})

class UserProfileForm {
  constructor(container) {
    this.container = container;

    const authPhoneInput = this.container.querySelector('#auth_normalized_phone');
    maskPhone(authPhoneInput);

    // this.logoForm = new Sti.LogoForm(this.container.querySelector('.profile-form-logo'));

    this.phonesFields = new Sti.PhonesFields(this.container.querySelector('.profile-form-phones'));
  }
}
