import loadYmaps from '../../shared/helpers/load_ymaps';

export default class Map {
  constructor(el) {
    this.el = el;
    let centerPoint = [this.el.dataset['centerLat'], this.el.dataset['centerLng']]

    loadYmaps(() => {
      ymaps.ready(() => this.createMap(el, centerPoint))
    })
  }

  createMap(el, centerPoint) {
    let options = {
      center: centerPoint,
      zoom: 12,
      controls: ['zoomControl']
    }
    let map = new ymaps.Map(el, options)
    let placemark = new ymaps.Placemark(centerPoint)
    map.geoObjects.add(placemark)
  }
}
