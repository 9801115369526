class Sti.TitleSeoPrefixFields
  constructor: (@form) ->
    @form = $ @form
    @toggleButton = @form.find '#toggle_title_seo_prefix_custom'
    @select = @form.find 'select[name$="[title_seo_prefix]"]'
    @input = @form.find 'input[name$="[title_seo_prefix]"]'
    @binds()

  binds: ->
    @toggleButton.on 'click', @toggleButtonClicked

  toggleButtonClicked: (event) =>
    @select.toggleDisabled().toggleClass 'hide'
    @input.toggleDisabled().toggleClass 'hide'

  reset: =>
    @select.children().remove()

  updatePrefixes: (prefixes) ->
    oldPrefixes = @select.find('option').map(-> this.text).toArray()

    if oldPrefixes.join() != prefixes.join()
      options = for prefix in prefixes
        "<option value='#{prefix}'>#{prefix}</option>"
      @select.html options.join("\n")

      randomPrefix = prefixes[Math.floor(Math.random()*prefixes.length)]
      @select.val randomPrefix
