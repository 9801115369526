jQuery ->
  $('#main_search').on 'focus', '.bar', ->
    Sti.MainSearchControl.get()

  if $('#main_search .bar').length and $('#main_search .bar').val().length
    Sti.MainSearchControl.get().input.trigger 'keyup'

# Singleton
class Sti.MainSearchControl
  _instance = undefined

  @get: ->
    _instance ?= new Sti._MainSearchControl
      container: $('#main_search')[0]
      templatesPath: 'shared/main_search'

class Sti._MainSearchControl extends Sti.SuggestionSearchControl
  focusedLinePressed: =>
    focus = @container.find('ol li.focus')
    unless focus.length == 0
      window.location = focus.find('a').attr('href')

  search: (value) =>
    @animation()
    @lastRequestTimestamp = Date.now()
    data = { value: value, timestamp: @lastRequestTimestamp }
    urlParams = document.URL.split('?')[1]
    if urlParams && urlParams.length > 0
      match = urlParams.match /city=(\d+)/
      if match
        data.city = match[1]
    $.ajax
      url: '/search'
      dataType: 'json'
      data: data
      success: @showResults
      error: @showError
      complete: @stopAnimation
