import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bid--date"
export default class extends Controller {
  static targets = ["date", "input"]

  connect() {
    this.dateTarget.addEventListener("input", () => {
      const date = new Date(Date.parse(this.dateTarget.value));
      const localeDate = date.toLocaleDateString('ru-RU');
      this.inputTarget.value = this.inputTarget.value + ' ' + localeDate;
    })
  }
}
