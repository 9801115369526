//= require 'lib/events'

Sti.Lib.on('.array-element', 'focusin', function (event) {
  var currentElement = event.target;
  var inputGroup = currentElement.parentNode;
  var elements = inputGroup.querySelectorAll('input');
  var lastElement = elements[elements.length - 1];

  if (currentElement === lastElement) {
    newElement = currentElement.cloneNode();
    newElement.value = '';
    lastElement.insertAdjacentElement('afterend', newElement);

  };
})
