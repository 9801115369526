import Cropper from 'cropperjs';

jQuery ->
  new Sti.LogoForm $('#logo_form') if $('#logo_form').length > 0

class Sti.LogoForm
  constructor: (@form)->
    @field = @form.find '#field'
    @inputs =
      file: @field.find 'input#logo_file'
      persisted: @field.find 'input#logo_persisted'
      cropSide: @field.find 'input#logo_crop_side'
      cropX: @field.find 'input#logo_crop_x'
      cropY: @field.find 'input#logo_crop_y'

    @reflection = @form.find '#reflection'
    @cropperModal = @form.find '#cropperModal'

    @buttons =
      destroy: @reflection.find '#destroy_logo'
      crop: @reflection.find '#crop_logo'
      saveCropping: @cropperModal.find '#save'

    @errorsContainer = @form.find '#errors'
    @croppingImage = @cropperModal.find('#cropbox img')
    @binds()

  binds: ->
    @buttons.saveCropping.on 'click', @saveCropping

    @cropperModal.on 'shown', =>
      unless @buttons.crop.hasClass 'disabled'
        @buttons.crop.addClass 'disabled'

        width = parseInt @croppingImage.width()
        height = parseInt @croppingImage.height()
        side = parseInt @inputs.cropSide.val()
        cropX = parseInt @inputs.cropX.val()
        cropY = parseInt @inputs.cropY.val()

        data =
          x: cropX
          y: cropY
          width: side
          height: side
          scaleX: 1
          scaleY: 1

        new Cropper @croppingImage[0],
          viewMode: 1,
          aspectRatio: 1,
          crop: @update,
          data: data

    @cropperModal.on 'hidden', =>
      @buttons.crop.removeClass 'disabled'

    @inputs.file.on 'change', (event) =>
      files = @inputs.file[0].files

      if (files and files[0])
        @sendLogo files[0]

    @buttons.destroy.click @destroy
    @buttons.crop.click @showCropperModal

  sendLogo: (file) =>
    @startUploading()

    form = @inputs.file.form
    url = '/user/logo'
    formData = new FormData
    formData.append 'logo[file]', file
    formData.append 'authenticity_token', window.Sti.authencityToken

    $.ajax
      url: url
      type: 'post'
      data: formData
      processData: false # need to send FormData
      contentType: false # need to send FormData
      success: @successfulyUploaded
      dataType: 'json'
      error: @errorOnUploading

  showCropperModal: (event) =>
    @cropperModal.modal 'show'
    false

  hideCropperModal: (event) =>
    @cropperModal.modal 'hide'

  startUploading: =>
    @field.spin Sti.Helpers.spinnerSettings color: 'white'

  successfulyUploaded: (logo) =>
    @field.spin false
    miniImg = $ "<img src='#{logo.file.mini.url}' class='img-polaroid' id='logo_mini' />"
    @reflection.prepend miniImg
    @field.hide()
    @reflection.show()

    # ensures that next request will be to user/logo#update
    methodInput = @form.find('[name="_method"]')
    if methodInput.length == 0
      methodInput = $ '<input name="_method" type="hidden" value="put">'
      @form.find('div:first').append methodInput
    else
      methodInput.val 'patch'

    @cropperModal.find('#cropbox').remove()

    img = $("<img src='#{logo.file.precropped.url}?#{Math.random()}' />")
    @croppingImage = img.clone()

    cropbox = $("<div class='cropbox' id='cropbox'>").append(@croppingImage)
    @cropperModal.find('.modal-body').append cropbox

    @croppingImage.bind 'load', @showCropperModal

  errorOnUploading: (xhr) =>
    @field.spin false
    errors = $.parseJSON(xhr.responseText)
    messages = ['Не удалось загрузить изображение.']
    if errors.file
      messages = messages.concat errors.file
    else
      messages.push "Проверьте Ваше интернет-соединение и попробуйте перезагрузить страницу.
       Если ошибка будет повторяться, свяжитесь, пожалуйста, с администратором сайта."
    alert messages.join("\n")

  saveCropping: =>
    unless @buttons.saveCropping.hasClass 'disabled'
      @buttons.saveCropping.addClass 'disabled'
      @buttons.saveCropping.spin Sti.Helpers.spinnerSettings left: '-30px'

      $.ajax
        data: @form.serializeArray()
        dataType: 'json'
        url: '/user/logo'
        type: 'POST'
        success: @successfulyCroped
        error: @errorOnCropping
    false

  successfulyCroped: (logo) =>
    @buttons.saveCropping.spin false
    @buttons.crop.removeClass 'disabled'
    @buttons.saveCropping.removeClass 'disabled'
    @reflection.find('#logo_mini').attr('src', logo.file.mini.url + '?' + Math.random())
    @hideCropperModal()

  errorOnCropping: (xhr) =>
    @buttons.saveCropping.spin false
    @buttons.saveCropping.removeClass 'disabled'

    alert('Не удалось обрезать изображение. Проверьте Ваше интернет-соединение и попробуйте перезагрузить страницу. Если ошибка будет повторяться, свяжитесь, пожалуйста, с администратором сайта.')

  update: (event) =>
    coords = event.detail
    console.log coords
    @inputs.cropSide.val Math.min coords.width, coords.height
    @inputs.cropX.val coords.x
    @inputs.cropY.val coords.y

  destroy: (event) =>
    unless @buttons.destroy.hasClass 'disabled'
      @buttons.destroy.addClass 'disabled'
      @hideCropperModal()
      @reflection.spin Sti.Helpers.spinnerSettings()
      $.ajax
        dataType: 'json'
        url: '/user/logo'
        type: 'delete'
        success: @successfulyDestroyed
        error: @errorOnDestroying
    false

  successfulyDestroyed: =>
    @buttons.destroy.removeClass 'disabled'
    @reflection.spin false
    @cropperModal.find('#cropbox').remove()
    @reflection.find('img#logo_mini').remove()
    @form.find('[name="_method"]').val 'post' # ensures that next request will be to user/logo#update
    @inputs.cropX.val null
    @inputs.cropY.val null
    @inputs.cropSide.val null
    @field.removeClass('hidden').show()
    @reflection.hide()

  errorOnDestroying: (xhr) =>
    @buttons.destroy.removeClass 'disabled'
    alert('Не удалось удалить изображение. Проверьте Ваше интернет-соединение и попробуйте перезагрузить страницу. Если ошибка будет повторяться, свяжитесь, пожалуйста, с администратором сайта.')
