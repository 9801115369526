require('./post_posting/placements.coffee');

import TechForm from './post_posting/tech_form.js';
import BidForm from './post_posting/bid_form.js';
import ServiceForm from './post_posting/service_form.js';
import SellForm from './post_posting/sell_form.js';
import SpareForm from './post_posting/spare_form.js';
import RepairForm from './post_posting/repair_form.js';

const idClassMap = {
  'tech': TechForm,
  'bid': BidForm,
  'service': ServiceForm,
  'sell': SellForm,
  'spare': SpareForm,
  'repair': RepairForm,
}

document.addEventListener('DOMContentLoaded', function() {
  for(const [id, klass] of Object.entries(idClassMap)) {
    const formEl = document.querySelector(`#${id}_form`);
    if (formEl) { new klass(formEl) }
  }
})

