import MobileModal from "./mobile/modal";

const el = document.querySelector("#mobile_main_menu");

if (el) {
  const mobileMainMenu = new MobileModal(el);
  const switcherEl = document.querySelector('#mobile_main_menu_switcher');

  if (switcherEl) {
    switcherEl.addEventListener('click', (e) => mobileMainMenu.toggle());
  }
}

