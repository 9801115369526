import csrfToken from './authenticity_token.js';

// export default function fetchWithCsrf(url, options = {}) {
//   // Set default options and merge with input
//   const defaultOptions = {
//     headers: {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//     },
//   };
//   // If method is not GET, ensure credentials include for CSRF protection
//   if (options.method && options.method.toUpperCase() !== 'GET') {
//     defaultOptions.headers['X-CSRF-Token'] = csrfToken();
//   }

//   const combinedOptions = { ...defaultOptions, ...options };

//   return fetch(url, combinedOptions)
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       return response.json();
//     });
// }


export default async function fetchWithCsrf(url, options = {}) {
  // Set default options and merge with input
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  };

  // If method is not GET, ensure credentials include for CSRF protection
  if (options.method && options.method.toUpperCase() !== 'GET') {
    defaultOptions.headers['X-CSRF-Token'] = csrfToken();
  }

  const combinedOptions = { ...defaultOptions, ...options };

  const response = await fetch(url, combinedOptions);
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}
