export default class SellForm {
  formAttrName = 'sell';

  constructor(el) {
    this.el = el;
    this.brandInput = el.querySelector('#sell_brand');

    new Sti.PhotoFields({
      container: this.el.querySelector('.photo-fields'),
      imageableName: this.formAttrName,
      photoType: 'tech_photo',
    });

    new Sti.PhonesFields(this.el.querySelector('.profile-form-phones'));

    const descriptionInput = this.el.querySelector('[name$="[description]"]');
    new Sti.LimitedLengthField(descriptionInput);

    this.category = this.el.querySelector('[name$="[category_id]"]');
    this.titleSeoPrefixFields = new Sti.TitleSeoPrefixFields(this.el);
    this.eavValues = new Sti.EavValuesFields(this);

    this.category = this.el.querySelector('[name$="[category_id]"]');

    this.initEvents();
  }

  initEvents() {
    this.category.addEventListener('change', (e) => { this.updateTechCategory(e) });
  }

  updateTechCategory(event) {
    const value = event.target.value;
    if (value && value.length) {
      const url = '/sell_categories/' + value;

      fetch(url)
        .then((r) => r.json())
        .then((json) => { this.categoryUpdated(json) })
    } else {
      this.categoryReset();
    }
  }

  categoryReset() {
    this.eavValues.reset();
    this.titleSeoPrefixFields.reset();
  }

  categoryUpdated(categoryJson) {
    this.updateTitleSeoPrefixes(categoryJson.sell_title_seo_prefixes);
    this.eavValues.categoryUpdated(categoryJson);
  }

  updateTitleSeoPrefixes(prefixes) {
    this.titleSeoPrefixFields.updatePrefixes(prefixes);
  }
}


