// app/javascript/channels/message_channel.js
import consumer from "../consumer"

// Function to subscribe to MessageChannel with a given conversation_id
const subscribeToMessageChannel = (accountId, recievedCallback) => {
  return consumer.subscriptions.create(
    { channel: 'Api::V1::Chat::MessagesChannel', account_id: accountId },
    {
      connected() { console.log('connected to message channel') },

      disconnected() { console.log('disconnected from message channel') },

      received(data) {
        recievedCallback(data);
      },

      updateStatus(id, status) {
        this.perform('update_status', { message_id: id, status: status });
      }
    }
  );
}

export default subscribeToMessageChannel;
