import fetchWithCsrf from "../../src/shared/helpers/fetch_json_with_csrf"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: Number }

  connect() {
  }

  toggleDetails() {
    this.element.classList.toggle("-expanded")
  }

  toggleDone(input) {
    const done = input.target.checked;
    let url;

    if (done) {
      url = `/admin/tasks/${this.idValue}/done`
    } else {
      url = `/admin/tasks/${this.idValue}/undone`
    }

    fetchWithCsrf(url, { method: 'PATCH' })
      .then(response => {
        if (response.done) {
          this.done();
        } else {
          this.undone();
        }
      })

  }

  done() {
    if (this.element.tagName === "TR") {
      this.element.classList.add("success")
      this.element.classList.remove("error")
    } else {
      this.element.classList.add("-done")
    }
  }

  undone() {
    if (this.element.tagName === "TR") {
      this.element.classList.remove("success")
    } else {
      this.element.classList.remove("-done")
    }
  }
}
