import Phone from '../phone';

export default function maskEmailOrPhone(input) {
  input.addEventListener('blur', function(e) {
    const input = e.currentTarget;

    if (input.value.length > 0) {
      const digits = input.value.replace(/\D/g, '');
      if (digits.length === 11) {
        input.value = Phone.applyMask(digits);
      }
    }
  })
}
