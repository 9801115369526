module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"region_area-container\">\n  <h4>Выберите район</h4>\n  <div class=\"placements-container\">\n    ")
    ;  Sti.Helpers.arrayInColumns(regionAreas, 4).for_each(function(regionAreas) { 
    ; __append("\n      <div class=\"placements-column\">\n        ")
    ;  regionAreas.for_each(function(regionArea) { 
    ; __append("\n          <button\n            class=\"action-link\"\n            role=\"region-area\"\n            data-region-area-id=\"")
    ; __append(escapeFn( regionArea.id ))
    ; __append("\">\n\n            ")
    ; __append(escapeFn( regionArea.name ))
    ; __append("\n          </button>\n        ")
    ;  }) 
    ; __append("\n      </div>\n    ")
    ;  }) 
    ; __append("\n  </div\n</div>\n")
  }
  return __output;

}