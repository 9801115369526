module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"control-group optional eav-multiple-control ")
    ; __append(escapeFn( attr.important ? '' : 'd-hidden' ))
    ; __append("\">\n  <label>\n    ")
    ; __append(escapeFn( attr.title ))
    ; __append("\n      ")
    ;  if (attr.measure.length) { 
    ; __append(", ")
    ; __append(escapeFn( attr.measure ))
    ; __append("\n          ")
    ;  } 
    ; __append("\n  </label>\n  <div class=\"controls\">\n    <input type=\"hidden\" name=\"")
    ; __append(escapeFn( namePrefix + '[attr_id]' ))
    ; __append("\" value=\"")
    ; __append(escapeFn( attr.id ))
    ; __append("\">\n    <input type=\"hidden\" name=\"")
    ; __append(escapeFn( namePrefix + '[type]' ))
    ; __append("\" value=\"")
    ; __append(escapeFn( attr.value_class_name ))
    ; __append("\">\n\n    ")
    ;  for (var i=0; i < attr.string_options.length; i++) { 
    ; __append("\n      ")
    ;  option=attr.string_options[i] 
    ; __append("\n        <span class=\"checkbox\">\n          <label><input type=\"checkbox\" name=\"")
    ; __append(escapeFn( namePrefix ))
    ; __append("[value][]\" value=\"")
    ; __append(escapeFn( option ))
    ; __append("\" class=\"optional\">\n            ")
    ; __append(escapeFn( option ))
    ; __append("\n          </label>\n        </span>\n        ")
    ;  } 
    ; __append("\n  </div>\n</div>\n")
  }
  return __output;

}