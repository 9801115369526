import { Controller } from "@hotwired/stimulus"
import getCurrentRegion from "../../src/shared/helpers/get_current_region";
import getCurrentCity from "../../src/shared/helpers/get_current_city";
import getRegionAreas from "../../src/shared/helpers/get_region_areas";
import renderTemplateHTML from "../../src/shared/helpers/render_template_html";

// Connects to data-controller="catalog--locality-switcher"
export default class extends Controller {
  static targets = ["secondaries"]

  connect() { }

  showSecondaries(e) {
    e.preventDefault();

    e.currentTarget.remove();
    this.secondariesTarget.classList.remove('d-hidden');
  }

  showRegionAreaCities(e) {
    e.preventDefault();

    this.getRegionAreaCitiesModal().show();
  }

  getRegionAreaCitiesModal() {
    if (!this.regionAreaCitiesModal) {
      const region = getCurrentRegion();
      const city = getCurrentCity();
      this.regionAreaCitiesModal = new RegionAreaCitiesModal(region, city);
    }

    return this.regionAreaCitiesModal;
  }
}

class RegionAreaCitiesModal {
  constructor(region, city) {
    this.region = region;
    this.city = city;
    this.regionAreas = getRegionAreas(region.id);

    this.modal = renderTemplateHTML('general/cities_in_region_areas', { regionAreas: this.regionAreas })[0];
    document.body.appendChild(this.modal);
  }

  show() {
    $(this.modal).modal('show');
  }
}
