import ShowMoreItemsComponent from '../../shared/show_more_items_component'
import AppendableSuggestions from '../../general/fields/appendable_suggestions'

export default class SpareForm {
  formAttrName = 'spare';

  spareCategoryMessages = {
    info: (limit) => `Максимальное число типов запчастей, которое можно выбрать — ${limit.overall}, уже добавили — ${limit.reached}.`,
    hitLimit: (limit) => `Выбрано максимальное количество типов запчастей — ${limit.overall}.`
  }
  techCategoryMessages = {
    info: (limit) => `Максимальное число видов техники, которое можно выбрать — ${limit.overall} видов техники, уже добавили — ${limit.reached}.`,
    hitLimit: (limit) => `Выбрано максимальное количество видов техники — ${limit.overall}.`
  }

  constructor(el) {
    this.el = el;

    new ShowMoreItemsComponent(this.el, { comma: false })

    new Sti.PhotoFields({
      container: this.el.querySelector('.photo-fields'),
      imageableName: this.formAttrName,
      photoType: 'tech_photo',
    });

    new Sti.PhonesFields(this.el.querySelector('.profile-form-phones'));

    const spareCategoriesEl = this.el.querySelector('#spare_categories');
    const limitedCheckboxesSC = new Sti.LimitedCheckboxes(spareCategoriesEl, this.spareCategoryMessages)
    limitedCheckboxesSC.checkLimitsOnChange('[name="spare[spare_category_ids][]"]')

    const techCategoriesEl = this.el.querySelector('#tech_categories')
    const limitedCheckboxesTC = new Sti.LimitedCheckboxes(techCategoriesEl, this.techCategoryMessages)
    limitedCheckboxesTC.checkLimitsOnChange('[name="spare[tech_category_ids][]"]')

    const descriptionInput = this.el.querySelector('[name$="[description]"]')
    new Sti.LimitedLengthField(descriptionInput);
    new AppendableSuggestions(descriptionInput);
  }
}


