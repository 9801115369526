import getRegionBySubdomain from './get_region_by_subdomain';

export default function getCurrentRegion() {
  const hostnameParts = location.hostname.split('.');

  if (hostnameParts.length == 2) { return null }

  const subdomain = hostnameParts[0];
  return getRegionBySubdomain(subdomain)
}
