export default class {
  constructor(el) {
    this.setState('hidden');

    this.el = el;
    this.firstLevel = el.querySelectorAll('.first-level');
    this.secondLevel = el.querySelectorAll('.second-level');
    this.closeBtns = el.querySelectorAll('.close');
    this.prevBtns = el.querySelectorAll('.prev');
    this.submenuBtns = el.querySelectorAll('[data-target]');

    this.binds()
  }

  binds() {
    this.closeBtns.forEach(el => {
      el.addEventListener('click', (e) => this.hide());
    });

    this.prevBtns.forEach(el => {
      el.addEventListener('click', (e) => this.prevBtnClicked());
    });

    this.submenuBtns.forEach(el => {
      el.addEventListener('click', (e) => this.submenuBtnClicked(e));
    })
  }

  setState(state) {
    this.state = state;
  }

  isVisible() {
    return this.state === 'visible'
  }

  toggle() {
    if (this.isVisible()) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    this.setState('visible');

    if (window.scrollTo) {
      this.scrollPositionY = window.scrollY;
      window.scrollTo(0, 0)
    }

    this.showElement(this.el);
  }

  hide() {
    this.setState('hidden');
    if (this.scrollPositionY && window.scrollTo) {
      window.scrollTo(0, this.scrollPositionY)
    }
    this.hideElement(this.el)
  }

  submenuBtnClicked(e) {
    e.preventDefault();

    const targetSelector = e.currentTarget.dataset['target'];
    const submenu = this.el.querySelector(targetSelector);

    this.hideFirstLevel()
    this.showElement(submenu);
  }

  hideFirstLevel() {
    this.firstLevel.forEach(this.hideElement);
  }

  showFirstLevel() {
    this.firstLevel.forEach(this.showElement);
  }

  hideSecondLevel() {
    this.secondLevel.forEach(this.hideElement);
  }

  hideElement(el) {
    el.style.display = 'none';
  }

  showElement(el) {
    el.style.display = 'block';
  }

  prevBtnClicked() {
    this.showFirstLevel();
    this.hideSecondLevel();
  }
}
