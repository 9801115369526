module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class='modal contact-request-modal' tabindex='-1'>\n  <div class='modal-header'>\n    <button type=\"button\" class='close' data-dismiss=\"modal\" aria-hidden=\"true\">×</button>\n    <h3>\n      ")
    ; __append(escapeFn( title ))
    ; __append("\n    </h3>\n  </div>\n\n  <div class='modal-body'>\n    ")
    ; __append(escapeFn( body ))
    ; __append("\n  </div>\n</div>\n")
  }
  return __output;

}