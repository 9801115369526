module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"mobile-modal\">\n  <div class=\"first-level\">\n    <div class=\"header\">\n      <h3>Выбор региона</h3>\n      <button class=\"close\">×</button>\n    </div>\n    <div class=\"content navbar\">\n      <p class=\"hint\">Выберите федеральный округ</p>\n      <ul class=\"mainmenu navbar-inner\">\n        ")
    ;  districts.forEach(function(district) { 
    ; __append("\n          <li class=\"item is-submenu\n                    ")
    ;  if (currentRegion && currentRegion.federal_district_id == district.id) { 
    ; __append("\n                      active\n                    ")
    ;  } 
    ; __append("\">\n            <a href=\"#\" data-target=\"#mobile_regions_district_")
    ; __append(escapeFn( district.id ))
    ; __append("\">\n              ")
    ; __append(escapeFn( district.title ))
    ; __append("\n              ")
    ;  if (currentRegion && currentRegion.federal_district_id === district.id) { 
    ; __append("\n                <p class=\"caption\">")
    ; __append(escapeFn( currentRegion.name ))
    ; __append("</p>\n              ")
    ;  } 
    ; __append("\n            </a>\n          </li>\n        ")
    ;  }) 
    ; __append("\n      </ul>\n    </div>\n  </div>\n\n  ")
    ;  districts.forEach(function(district) { 
    ; __append("\n    <div class=\"second-level\" id=\"mobile_regions_district_")
    ; __append(escapeFn( district.id ))
    ; __append("\">\n      <div class=\"header\">\n        <button class=\"prev\">‹</button>\n        <h3>Выбор региона</h3>\n        <button class=\"close\">×</button>\n      </div>\n      <div class=\"content navbar\">\n        <ul class=\"mainmenu navbar-inner\">\n          ")
    ;  var regions = Sti.Helpers.regionsByFederalDistrictId(district.id) 
    ; __append("\n          ")
    ;  regions.forEach(function(region) { 
    ; __append("\n            <li class=\"item\n              ")
    ;  if(currentRegion && currentRegion == region) { 
    ; __append("active")
    ;  } 
    ; __append("'\n            \">\n              <a href=\"")
    ; __append(escapeFn( Sti.Helpers.changeUrlSubdomain(location.href, region.subdomain) ))
    ; __append("\">\n                ")
    ; __append(escapeFn( region.name ))
    ; __append("\n              </a>\n            </li>\n          ")
    ;  }) 
    ; __append("\n        </ul>\n      </div>\n    </div>\n  ")
    ;  }) 
    ; __append("\n</div>\n")
  }
  return __output;

}