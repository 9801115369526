export default function AppendableSuggestions(input) {
  function appendInputWithSuggestion(e) {
    e.preventDefault();

    var suggestion = e.target.innerText;
    if (input.value.length) {
      input.value = input.value + ' ';
    }
    input.value = input.value + suggestion;

    return;
  }

  var suggestions = input.dataset['appendableSuggestions'];
  if (!suggestions) { return };
  suggestions = JSON.parse(suggestions);
  if (!suggestions) { return };

  var block = document.createElement('p');
  block.classList.add('appendable-suggestions');

  for (var i = 0; i < suggestions.length; i++) {
    var btnLink = document.createElement('a');
    btnLink.href = '#'
    btnLink.classList.add('appendable-suggestion');
    btnLink.innerText = suggestions[i];
    btnLink.addEventListener('click', appendInputWithSuggestion, false);

    block.append(btnLink);
  }

  input.parentNode.append(block);
}
