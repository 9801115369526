import { Controller } from "@hotwired/stimulus"
import fetchWithCsrf from "../../src/shared/helpers/fetch_json_with_csrf"
import maskPhone from '../../src/shared/helpers/mask_phone';

// Connects to data-controller="form--anonymous-phone"
export default class extends Controller {
  static targets = ["plain", "error"]
  static values = { id: String }

  connect() {
    this.plainTargets.forEach((plain) => {
      maskPhone(plain)
    })
  }

  async destroy(e) {
    e.preventDefault()

    if (this.idValue == "new") {
      this.destroyStub()
      return
    }

    const url = "/phones/" + this.idValue;

    let message;
    try {
      const response = await fetchWithCsrf(url, { method: 'DELETE' })
      if (response.ok) {
        this.element.remove();
      } else {
        message = 'Этот телефон нельзя удалить; возможно это единственный телефон одном или нескольких объявелниях.'
      }
    } catch (error) {
      message = 'Не удалось удалить телефон. Повторите попытку позже'
    }
    this.errorTarget.innerText = message
  }


  destroyStub() {
    this.removeElement();
    this.dispatch("destroy", { detail: {} });
  }

  removeElement() {
    this.element.remove()
  }
}

// class Sti.PhoneFields
//   constructor: (@target, @form) ->
//     input = @target[0].querySelector('[name$="[plain]"]')
//     maskPhone(input)

//     @destroyBtn = @target.find '.destroy'
//     @error = @target.find '.error'
//     @binds()

//   binds: =>
//     @destroyBtn.on 'click', @destroyBtnClicked

//   destroyBtnClicked: (event) =>
//     event.preventDefault()
//     # event.stopPropagation()

//     method = @destroyBtn.data('method')
//     if method == 'stub'
//       @remove()
//     else
//       $.ajax
//         url: @destroyBtn.attr('href')
//         type: 'delete'
//         success: @remove
//         dataType: 'json'
//         error: (xhr) =>
//           if xhr.status == 422
//             @error.text 'Этот телефон нельзя удалить, потому что в одном или нескольких объявлениях это единственный телефон.'
//           else
//             @error.text 'Не удалось удалить телефон.'
//     false

//   remove: =>
//     @target.fadeOut =>
//       @target.next('input[type="hidden"]').remove()
//       @target.remove()
//       @form.checkAddBtnPresence()
