import { Controller } from "@hotwired/stimulus"
import PostCard from '../../src/general/post/card.js'
import isElementInViewport from '../../src/shared/helpers/is_element_in_viewport'

// Connects to data-controller="catalog--infinite-scroll"
export default class extends Controller {
  connect() {
    this.url = this.element.href;
    this.page = parseInt(this.url.match(/page=(\d+)/)[1]);
    this.postIds = Array.from(document.querySelectorAll('.post-card')).map(el => el.id);

    this.binds();
  }

  binds() {
    this.element.addEventListener('click', this.btnClicked.bind(this));
    window.addEventListener('scroll', this.windowScrolled.bind(this));
  }

  windowScrolled(event) {
    event.preventDefault();

    if (isElementInViewport(this.element, { topOffset: 100 })) {
      if (!this.element.hasAttribute('disabled')) {
        this.loadAndInsert();
      }
    }
  }

  btnClicked(event) {
    event.preventDefault();

    this.loadAndInsert();
  }

  loadAndInsert() {
    this.disableBtn();
    this.load(posts => {
      if (posts.length) {
        this.insertPosts(posts);
        this.updateUrl();
      } else {
        this.element.style.display = 'none';
      }
    });
  }

  insertPosts(loadedPosts) {
    const posts = Array.from(loadedPosts)
      .filter(post => post.classList.contains('post-card'))
      .filter(post => !this.postIds.includes(post.id))

    if (posts.length) {
      posts.forEach(post => {
        this.postIds.push(post.id);
        this.activatePostCard(post);
        this.element.insertAdjacentElement('beforebegin', post);
      })
    } else {
      this.element.remove();
    };
  }

  activatePostCard(post) {
    new PostCard(post);
  }

  load(callback) {
    const request = fetch(this.url, { method: 'GET', headers: { 'X-Requested-With': 'fetch' } })
      .then(response => response.text())
      .then(html => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return Array.from(div.querySelectorAll('.post-card'));
      })
      .then(callback)
      .catch(error => {
        console.error('Error:', error)
      })
      .finally(() => this.enableBtn());

    this.element.classList.add('loading');

    return request
  }

  updateUrl() {
    this.page += 1;
    this.url = this.url.replace(/page=\d+/, `page=${this.page}`);
    this.element.href = this.url;
  }

  enableBtn() {
    this.element.removeAttribute('disabled');
    this.element.classList.remove('loading');
  }

  disableBtn() {
    this.element.setAttribute('disabled', 'disabled');
  }
}


// class Sti._LoadMorePosts
//   constructor: (@btn) ->
//     @url = @btn.attr 'href'
//     @page = parseInt @url.match(/page=(\d+)/)[1]
//     @postIds = $('.post-card').map((i, el) -> return el.id).toArray()
//     @binds()

//   binds: =>
//     @btn.on 'click', @btnClicked
//     window.addEventListener 'scroll', @windowScrolled

//   windowScrolled: (event) =>
//     event.preventDefault()

//     if Sti.Helpers.isElementInViewport(@btn, { topOffset: 100 })
//       @loadAndInsert() unless @btn.attr 'disabled'

//   btnClicked: (event) =>
//     event.preventDefault()

//     @loadAndInsert()

//   loadAndInsert: =>
//     @disableBtn()
//     @load (posts) =>
//       if posts.length
//         @insertPosts(posts)
//         @updateUrl()
//       else
//         @btn.hide()

//   insertPosts: (posts) =>
//     posts = $(posts)

//     for post in posts.filter('.post-card')
//       if @postIds.indexOf(post.id) == -1
//         @postIds.push(post.id)

//         new PostCard(post)
//         # new Sti.PostCard($ post)
//         # new Sti.Contact post.getElementsByClassName('contact-block')[0]
//       else
//         posts.remove(post)

//     posts.insertBefore(@btn)

//   load: (callback) =>
//     request = $.ajax
//       url: @url
//       type: 'get'
//       success: callback
//       error: (xhr) => alert('Не удалось загрузить объвления')
//       complete: => @enableBtn()

//     spinnerSettings = Sti.Helpers.spinnerSettings('mini',
//       left: (@btn.width() / 2 - 10), top: 0,
//       length: 4,
//       color: 'black',
//       stopWhenComplete: request)
//     @btn.spin(spinnerSettings)

//     # @btn.spin Sti.Helpers.extendSpinSettings
//     #   stopWhenComplete: request

//   updateUrl: =>
//     @page += 1
//     @url = @url.replace(/page=\d+/, "page=#{@page}")
//     @btn.attr 'href', @url

//   enableBtn: => @btn.removeAttr 'disabled'
//   disableBtn: => @btn.attr 'disabled', 'disabled'
