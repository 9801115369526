module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class='two-columns-modal catalog-cities-in-region-areas modal hide' role='dialog' tabindex='-1'\n  data-controller=\"catalog--cities-in-region-areas\">\n  <div class='modal-header'>\n    <button aria-hidden class='close' data-dismiss='modal' type='button'>×</button>\n    <h3>Выберите район и населенный пункт</h3>\n  </div>\n  <div class='modal-body'>\n    <div class='left-column' id='region_areas'>\n      <div class='header'>Район</div>\n      <ul>\n        ")
    ;  regionAreas.forEach(function(regionArea) { 
    ; __append("\n          <li class='left-column-item'>\n            <a href='#' class='left-column-item-title'\n              data-action=\"click->catalog--cities-in-region-areas#switchRegionArea\"\n              data-region-area='")
    ; __append(escapeFn( regionArea.id ))
    ; __append("'>\n              <span>\n                ")
    ; __append(escapeFn( regionArea.name ))
    ; __append("\n              </span>\n            </a>\n          </li>\n          ")
    ;  }) 
    ; __append("\n      </ul>\n    </div>\n    <div class='right-column'>\n      <div class='header'>Населенный пункт</div>\n      <ul class='right-column-items active' id='cities'>\n        <li>выберите район, для загрузки населенных пунктов</li>\n      </ul>\n    </div>\n  </div>\n</div>\n")
  }
  return __output;

}