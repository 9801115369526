module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  if (typeof users !== 'undefined' && users.length > 0) { 
    ; __append("\n  <h4>Пользователи:</h4>\n  <ol class=\"group\" id=\"search_results_users\">\n    ")
    ;  users.forEach(function(user) { 
    ; __append("\n      <li class='clearfix'>\n        <a href=\"")
    ; __append(escapeFn( user.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( user.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ; __append(escapeFn( user.phones ))
    ; __append("\n          </div>\n          <div class=\"city\">\n            ")
    ; __append(escapeFn( user.city ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof tech_catalogs !== 'undefined' && tech_catalogs.length > 0) { 
    ; __append("\n  <h4>Рубрики аренды спецтехники:</h4>\n  <ol class=\"group\" id=\"search_results_tech_categories\">\n    ")
    ;  tech_catalogs.forEach(function(catalog) { 
    ; __append("\n      <li class='clearfix'>\n        <a href=\"")
    ; __append(escapeFn( catalog.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( catalog.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ; __append(escapeFn( catalog.posts_count ))
    ; __append("\n          </div>\n          <div class=\"locality\">\n            ")
    ; __append(escapeFn( catalog.locality ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n\n")
    ;  if (typeof teches !== 'undefined' && teches.length > 0) { 
    ; __append("\n  <h4>Объявления об аренде спецтехники:</h4>\n  <ol class=\"group\" id=\"search_results_teches\">\n    ")
    ;  teches.forEach(function(tech) { 
    ; __append("\n      <li class='")
    ;  if (tech.premium) { 
    ; __append(" 'premium' ")
    ;  } 
    ; __append(" clearfix'>\n        <a href=\"")
    ; __append(escapeFn( tech.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( tech.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ; __append(escapeFn( tech.category ))
    ; __append("\n          </div>\n          <div class=\"city\">\n            ")
    ; __append(escapeFn( tech.city ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof bids !== 'undefined' && bids.length > 0) { 
    ; __append("\n  <h4>Заявки на аренду спецтехники:</h4>\n  <ol class=\"group\" id=\"search_results_bids\">\n    ")
    ;  bids.forEach(function(bid) { 
    ; __append("\n      <li class='clearfix'>\n        <a href=\"")
    ; __append(escapeFn( bid.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( bid.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ; __append(escapeFn( bid.category ))
    ; __append("\n          </div>\n          <div class=\"city\">\n            ")
    ; __append(escapeFn( bid.city ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof service_catalogs !== 'undefined' && service_catalogs.length > 0) { 
    ; __append("\n  <h4>Рубрики спецобъявлений:</h4>\n  <ol class=\"group\" id=\"search_results_tech_categories\">\n    ")
    ;  service_catalogs.forEach(function(catalog) { 
    ; __append("\n      <li class='clearfix'>\n        <a href=\"")
    ; __append(escapeFn( catalog.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( catalog.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ; __append(escapeFn( catalog.posts_count ))
    ; __append("\n          </div>\n          <div class=\"locality\">\n            ")
    ; __append(escapeFn( catalog.locality ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof services !== 'undefined' && services.length > 0) { 
    ; __append("\n  <h4>Спецобъявления:</h4>\n  <ol class=\"group\" id=\"search_results_services\">\n    ")
    ;  services.forEach(function(service) { 
    ; __append("\n      <li class='")
    ;  if (service.premium) { 
    ; __append(" 'premium' ")
    ;  } 
    ; __append(" clearfix'>\n        <a href=\"")
    ; __append(escapeFn( service.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( service.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ;  if (service.category) { 
    ; __append("\n              ")
    ; __append(escapeFn( service.category ))
    ; __append("\n            ")
    ;  } 
    ; __append("\n          </div>\n          <div class=\"city\">\n            ")
    ; __append(escapeFn( service.city ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n\n")
    ;  if (typeof sell_catalogs !== 'undefined' && sell_catalogs.length > 0) { 
    ; __append("\n  <h4>Рубрики о продаже техники:</h4>\n  <ol class=\"group\" id=\"search_results_tech_categories\">\n    ")
    ;  sell_catalogs.forEach(function(catalog) { 
    ; __append("\n      <li class='clearfix'>\n        <a href=\"")
    ; __append(escapeFn( catalog.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( catalog.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ; __append(escapeFn( catalog.posts_count ))
    ; __append("\n          </div>\n          <div class=\"locality\">\n            ")
    ; __append(escapeFn( catalog.locality ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof sells !== 'undefined' && sells.length > 0) { 
    ; __append("\n  <h4>Объявления о продаже:</h4>\n  <ol class=\"group\" id=\"search_results_services\">\n    ")
    ;  sells.forEach(function(sell) { 
    ; __append("\n\n      <li class='")
    ;  if (sell.premium) { 
    ; __append(" 'premium' ")
    ;  } 
    ; __append(" clearfix'>\n        <a href=\"")
    ; __append(escapeFn( sell.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( sell.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ;  if (sell.category) { 
    ; __append("\n              ")
    ; __append(escapeFn( sell.category ))
    ; __append("\n            ")
    ;  } 
    ; __append("\n          </div>\n          <div class=\"city\">\n            ")
    ; __append(escapeFn( sell.city ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof spare_catalogs !== 'undefined' && spare_catalogs.length > 0) { 
    ; __append("\n  <h4>Рубрики о продаже запчастей:</h4>\n  <ol class=\"group\" id=\"search_results_tech_categories\">\n    ")
    ;  spare_catalogs.forEach(function(catalog) { 
    ; __append("\n      <li class='clearfix'>\n        <a href=\"")
    ; __append(escapeFn( catalog.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( catalog.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ; __append(escapeFn( catalog.posts_count ))
    ; __append("\n          </div>\n          <div class=\"locality\">\n            ")
    ; __append(escapeFn( catalog.locality ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof spares !== 'undefined' && spares.length > 0) { 
    ; __append("\n  <h4>Запчасти:</h4>\n  <ol class=\"group\" id=\"search_results_services\">\n    ")
    ;  spares.forEach(function(spare) { 
    ; __append("\n      <li class='")
    ;  if (spare.premium) { 
    ; __append(" 'premium' ")
    ;  } 
    ; __append(" clearfix'>\n        <a href=\"")
    ; __append(escapeFn( spare.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( spare.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ;  if (spare.category) { 
    ; __append("\n              ")
    ; __append(escapeFn( spare.category ))
    ; __append("\n            ")
    ;  } 
    ; __append("\n          </div>\n          <div class=\"city\">\n            ")
    ; __append(escapeFn( spare.city ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof repair_catalogs !== 'undefined' && repair_catalogs.length > 0) { 
    ; __append("\n  <h4>Рубрики о ремонте:</h4>\n  <ol class=\"group\" id=\"search_results_tech_categories\">\n    ")
    ;  repair_catalogs.forEach(function(catalog) { 
    ; __append("\n      <li class='clearfix'>\n        <a href=\"")
    ; __append(escapeFn( catalog.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( catalog.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ; __append(escapeFn( catalog.posts_count ))
    ; __append("\n          </div>\n          <div class=\"locality\">\n            ")
    ; __append(escapeFn( catalog.locality ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n\n")
    ;  if (typeof repairs !== 'undefined' && repairs.length > 0) { 
    ; __append("\n  <h4>Ремонт спецтехники:</h4>\n  <ol class=\"group\" id=\"search_results_services\">\n    ")
    ;  repairs.forEach(function(repair) { 
    ; __append("\n      <li class='")
    ;  if (repair.premium) { 
    ; __append(" 'premium' ")
    ;  } 
    ; __append(" clearfix'>\n        <a href=\"")
    ; __append(escapeFn( repair.url ))
    ; __append("\">\n          <div class=\"title\">\n            ")
    ; __append(escapeFn( repair.title ))
    ; __append("\n          </div>\n          <div class=\"inline-caption\">\n            ")
    ;  if (repair.category) { 
    ; __append("\n              ")
    ; __append(escapeFn( repair.category ))
    ; __append("\n            ")
    ;  } 
    ; __append("\n          </div>\n          <div class=\"city\">\n            ")
    ; __append(escapeFn( repair.city ))
    ; __append("\n          </div>\n        </a>\n      </li>\n    ")
    ;  }) 
    ; __append("\n  </ol>\n")
    ;  } 
    ; __append("\n")
  }
  return __output;

}