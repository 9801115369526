import { Controller } from "@hotwired/stimulus"
import enRuTranscribe from '../src/shared/helpers/en_ru_transcribe'

// Connects to data-controller="category-plates"
export default class extends Controller {
  static targets = ['plate']

  connect() { }

  filter(e) {
    const value = e.currentTarget.value;
    this.plateTargets.forEach((plate) => { this.togglePlate(plate, value) })
  }

  match(plate, value) {
    const prompt = enRuTranscribe(value).toLowerCase();
    const titles = plate.dataset['titles'].toLowerCase();

    return titles.includes(prompt)
  }

  togglePlate(plate, value) {
    if (this.match(plate, value)) {
      plate.style.display = 'block';
    } else {
      plate.style.display = 'none';
    }
  }
}
