class Sti.SuggestionSearchControl
  constructor: (@args) ->
    @container = $(@args.container)
    @templatesPath = @args.templatesPath
    @minQueryLength = @args.minQueryLength || 3
    @delayTime = @args.delayTime || 400
    @input = @args.input or @container.find '.bar'
    @input = $(@input)
    @closeButton = @args.close or @container.find '.close'
    @results = @args.results or @container.find '.results'

    @afterConstructor() if @afterConstructor?
    @binds()

  binds: =>
    @input.on 'focus', @onFocus
    @input.on 'blur', @onBlur
    @input.on 'keydown', (event) =>
      switch event.keyCode
        when 38
          event.preventDefault()
          @moveFocus 'up'
        when 40
          event.preventDefault()
          @moveFocus 'down'
        when 27
          @hideResults()
        when 13
          if @isResultsVisible()
            event.preventDefault()

    @input.on 'keyup', (event) =>
      if event.keyCode == 13
        if @isResultsVisible()
          @focusedLinePressed()
        else
          return true
      else
        value = $.trim(event.target.value)
        if value == '' or value.length < @minQueryLength
          @lastValue = value
          @hideResults()
        else
          if @lastValue != value
            delay (@delayTime / 2), =>
              if value == $.trim(event.target.value) && @lastValue != value
                @search value
              @lastValue = value

      event.preventDefault()
      false

    if @closeButton.length
      @closeButton.on 'click', =>
        @input.val ''
        @hideCloseButton()
        @hideResults()
        @input.focus()

        false

  onFocus: (event) =>
    event.preventDefault()
    @container.addClass 'focused'
    if @input.val().length > 0
      delay @delayTime, =>
        if @results.children().length > 0 and @input.is(':focus')
          @fadeInResults()
    false

  isResultsVisible: => @results.is(':visible')

  fadeInResults: => @results.fadeIn(@delayTime / 2)
  fadeOutResults: => @results.fadeOut(@delayTime / 2)

  onBlur: =>
    @hideResults()
    delay 200, => @container.removeClass 'focused'

  animation: =>
    @hideCloseButton()
    @container.spin Sti.Helpers.spinnerSettings
      left: (@container.width() + 15) + 'px',
      top: '20px'
      radius: 6, length: 1
      color: 'gray'

  stopAnimation: =>
    @showCloseButton()
    @container.spin false

  hideResults: ->
    @results.removeClass '-shown'
    @fadeOutResults()

  showResults: (results) =>
    timestamp = parseInt(results.timestamp)
    if !timestamp or timestamp == @lastRequestTimestamp
      @showCloseButton()
      html = if @anyResultPresent(results)
        $ Sti.Helpers.renderTemplate "#{@templatesPath}/results", results
      else
        $ Sti.Helpers.renderTemplate "#{@templatesPath}/blank", results
      @results.html(html)
      @fadeInResults()
      @results.addClass '-shown'

  showError: =>
    html = $ Sti.Helpers.renderTemplate "#{@templatesPath}/error", {}
    @results.html(html).show()

  moveFocus: (direction) =>
    focus = @container.find 'li.focus'
    if direction == 'up'
      if focus.is(':first-child') && focus.closest('.group').prevAll('.group').length == 0 || focus.length == 0
        focus.removeClass 'focus'
        focus = @container.find('.group:last li:last')
        focus.addClass 'focus'
      else
        focus.removeClass 'focus'
        prev = if focus.is ':first-child'
          $(focus.closest('.group').prevAll('.group')[0]).find 'li:last'
        else
          focus.prev()
        prev.addClass 'focus'
    else
      if focus.is(':last-child') && focus.closest('.group').nextAll('.group').length == 0 || focus.length == 0
        focus.removeClass 'focus'
        focus = @container.find('.group:first li:first')
        focus.addClass 'focus'
      else
        focus = @container.find('.group li.focus')
        focus.removeClass 'focus'
        next = if focus.is ':last-child'
          $(focus.closest('.group').nextAll('.group')[0]).find 'li:first'
        else
          focus.next()
        next.addClass 'focus'

  anyResultPresent: (results) =>
    for name, collection of results
      return true if Array.isArray(collection) && collection?.length
    false

  showCloseButton: =>
    @closeButton.show() if @closeButton.length

  hideCloseButton: =>
    @closeButton.hide() if @closeButton.length

