export default function(selector, eventName, handler, el=document) {
  var els = [].concat(el);
  for(var i = 0; i < els.length; i++) {
    var el = els[i];
    el.addEventListener(eventName, function(event) {
      var t = event.target;
      while (t && t !== this) {
        if (t.matches(selector)) {
          handler.call(t, event);
        }
        t = t.parentNode;
      }
   })
  }
}
