import enRuTranscribe from "./helpers/en_ru_transcribe";
import debounce from "./helpers/debounce";

export default class Autocomplete {
  itemsLimit = 10;

  defaultDebounceTimeout = {
    preset: 50,
    url: 300
  };

  debounceTimeout(type) {
    switch (type) {
      case 'preset':
        return this.defaultDebounceTimeout.preset;
      case 'url':
        return this.defaultDebounceTimeout.url;
    }
  }

  constructor(input) {
    this.input = input;

    const dataset = input.dataset;

    if (dataset['autocompletePresetValue']) {
      this.type = 'preset';
    } else if (dataset['autocompleteUrlValue']) {
      this.type = 'url';
    } else {
      throw new Error('Autocomplete type is not specified');
    }

    switch (this.type) {
      case 'preset':
        this.presetList = this.fetchPreset(dataset['autocompletePresetValue']);
        break;
      case 'url':
        this.queryUrlTemplate = dataset['autocompleteUrlValue']
        break;
    }

    this.setFocused(null);
    this.wrapInput(input);
    this.setEvents();
  }

  setEvents() {
    this.input.addEventListener('blur', (e) => { this.inputBlured(e) });
    this.input.addEventListener('focus', (e) => { this.inputFocused(e) });
    this.input.addEventListener('keydown', (e) => { this.inputPressedDown(e) });

    const debouncedInputTriggered = debounce.apply(
      this,
      [this.inputTriggered, this.debounceTimeout(this.type)]
    );
    this.input.addEventListener('input', (e) => debouncedInputTriggered(e));

    this.resultsEl.addEventListener("mouseover", (e) => this.resultMouseOvered(e));
  };

  setFocused(item) {
    if (this.isResultsHidden()) { return }

    if (this.focusedItem) {
      this.focusedItem.classList.remove('-focused');
    }

    if (item) {
      this.focusedItem = item;
      this.focusedItem.classList.add('-focused');
    }
  }

  resultMouseOvered(event) {
    if (event.target.tagName === 'LI') {
      this.setFocused(event.target)
    }
  }

  inputFocused(event) {
    const value = event.target.value;
    this.querySuggestions(value);

    // this.showResults();
  }

  inputBlured(_event) {
    if (!this.isResultsVisible()) { return }

    this.hideResults();

    if (this.input.value.length === 0) { return }

    if (this.focusedItem) {
      this.selectItem(this.focusedItem);
    } else {
      const firstItem = this.resultsEl.querySelector('li:first-child');
      if (firstItem) {
        this.selectItem(firstItem)
      }
    }
  }

  inputPressedDown(event) {
    switch (event.keyCode) {
      case 13:
        event.preventDefault();
        this.enterPressed();
        break;
      case 38:
        this.arrowUpPressed()
        break;
      case 40:
        this.arrowDownPressed()
        break;
    }
  }

  inputTriggered(event) {
    const value = event.target.value;

    if (value.length === 0) {
      this.hiddenInput.value = '';
    } else {
      this.querySuggestions(value);
    }
  }

  querySuggestions() {
    const value = this.input.value.toLowerCase().trim();

    if (this.presetList) {
      this.filterPresetSuggestions(value, this.updateList);
    } else {
      this.fetchSuggestions(value, this.updateList);
    }
  }

  updateList(suggestions, value) {
    this.suggestions = suggestions;
    this.updateResults(suggestions, value);
  }

  filterPresetSuggestions(value, callback) {
    if (value.length === 0) {
      return []
    }

    const transribedValue = enRuTranscribe(value);
    const filteredSuggestions = this.presetList.filter((suggestion) => {
      const lowcased = suggestion.name.toLowerCase();

      return lowcased.indexOf(value) > -1 || lowcased.indexOf(transribedValue) > -1;
    })
    const limitedList = filteredSuggestions.slice(0, this.itemsLimit);

    callback.apply(this, [limitedList, value]);
  }

  queryUrl(value) {
    return this.queryUrlTemplate + "?q=" + value;
  }

  fetchSuggestions(value, callback) {
    fetch(this.queryUrl(value), { headers: { 'Accept': 'application/json' } })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      }).then((response) => {
        const suggestions = response.suggestions;
        const limitedList = suggestions.slice(0, this.itemsLimit);
        callback.apply(this, [limitedList, value]);
      })
  }

  showResults() {
    if (this.resultsEl.innerHTML.length) {
      this.resultsEl.classList.add('-shown');
      this.setFocused(this.resultsEl.querySelector('.-focused'));
    };
  }

  hideResults() {
    setTimeout(() => {
      this.resultsEl.classList.remove('-shown');
    }, 0);
  }

  isResultsVisible() {
    return this.resultsEl.classList.contains('-shown');
  }

  isResultsHidden() {
    return !this.isResultsVisible();
  }

  isResultsVisible() {
    return this.resultsEl && this.resultsEl.classList.contains('-shown');
  }

  updateResults(suggestions, value) {
    if (suggestions.length === 0) {
      this.hideResults();
    } else {
      this.renderResults(suggestions, value);
      this.showResults();
    }
  }

  renderResults(suggestions, value) {
    const html = [];
    suggestions.forEach((suggestion) => {
      let classes = [];
      if (this.focusedItem) {
        const id = parseInt(this.focusedItem.dataset['id']);
        if (id === suggestion.id) {
          classes.push('-focused');
        }
      }
      const caption = suggestion.name.replace(RegExp(`(${value})`, 'i'), '<b>$1</b>');
      html.push(`<li data-id="${suggestion.id}" class="${classes.join(' ')}">${caption}</li>`)
    });

    this.resultsEl.innerHTML = html.join('');
  }

  wrapInput(input) {
    this.container = document.createElement('div');
    this.container.classList.add('autocomplete-container');

    this.hiddenInput = document.createElement('input');
    this.hiddenInput.type = 'hidden';
    this.hiddenInput.name = input.name;
    this.hiddenInput.value = input.dataset['autocompleteValueValue'] || input.value;

    this.resultsEl = document.createElement('ol');
    this.resultsEl.style = `width: ${input.offsetWidth - 2}px;`
    this.resultsEl.classList.add('autocomplete-results');

    const inlineHint = input.parentNode.querySelector('.form-inline_hint');

    input.parentNode.insertBefore(this.container, input);
    this.container.appendChild(input);
    if (inlineHint) { this.container.appendChild(inlineHint); };
    this.container.appendChild(this.resultsEl);
    this.container.appendChild(this.hiddenInput);
  }

  fetchPreset(suggestions) {
    const preset = JSON.parse(suggestions) || [];
    return this.normalizePreset(preset);
  }

  normalizePreset(preset) {
    return preset.map((item) => {
      if (typeof item === 'object' && item !== null) {
        return item;
      } else if (typeof item === 'string') {
        return { id: item, name: item }
      } else {
        throw 'Preset item should be either string or object'
      }
    })
  }

  arrowUpPressed() {
    this.moveFocus('up')
  }

  arrowDownPressed() {
    this.moveFocus('down')
  }

  enterPressed() {
    if (this.focusedItem) {
      this.selectItem(this.focusedItem)
    };
  }

  selectItem(focusedItemEl) {
    const id = focusedItemEl.dataset['id'];
    const name = focusedItemEl.innerText;

    this.hiddenInput.value = id;
    this.input.value = name;

    this.hideResults()
  }

  moveFocus(direction) {
    if (direction === 'up') {
      this.focusPrevious();
    } else {
      this.focusNext();
    }
  }

  focusPrevious() {
    if (this.focusedItem && this.focusedItem.previousElementSibling) {
      const previous = this.focusedItem.previousElementSibling;
      this.setFocused(previous);
    } else {
      const last = this.resultsEl.querySelector('li:last-child');
      this.setFocused(last);
    }
  }

  focusNext() {
    if (this.focusedItem && this.focusedItem.nextElementSibling) {
      const next = this.focusedItem.nextElementSibling;
      this.setFocused(next);
    } else {
      const first = this.resultsEl.querySelector('li:first-child');
      this.setFocused(first);
    }
  }
}
