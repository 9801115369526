jQuery ->
  if (el = $('#post_placements')).length
    new Sti.PostPlacements(el)

class Sti.PostPlacements
  createUrl: (number) => "/post/#{number}/placements"
  destroyUrl: (number) => "/placements/#{number}"

  constructor: (@el) ->
    @postNumber = @el.data('postNumber')
    @otherRegionAreasBtn = @el.find('[role="other-region-areas"]')
    @otherRegionAreas = @el.find('.region_areas-container')
    @otherRegionsBtn = @el.find('[role="other-regions"]')
    @otherRegions = @el.find('.regions-container')

    msgs = {}
    msgs.info = (limit) ->
      "Максимальное количество простых публикаций для этой рубрики: " +
      "#{limit.overall}, уже добавили: #{limit.reached}"
    msgs.hitLimit = (limit) ->
      "Вы создали максимальное количество простых " +
      "публикаций для этой рубрики: #{limit.overall}. " +
      "Подключите премиум для ваших публикаций или снимите публикации " +
      "в этом или других объявлениях рубрики, чтобы добавить новые публикации." +
      "Или свяжитесь с менеджером: #{limit.support_phone}"

    @limitedCheckboxes = new Sti.LimitedCheckboxes @el, msgs

    @binds()

  binds: ->
    @otherRegionAreasBtn.on('click', @otherRegionAreasBtnClicked)
    @otherRegionsBtn.on('click', @otherRegionsBtnClicked)
    $(@el).on('click', '[role=toggle-placement]', @localityCheckboxClicked)
    @el.on('click', '[role="region-area"]', @renderRegionAreaBtnClicked)
    @el.on('click', '[role="region"]', @renderRegionBtnClicked)

  localityCheckboxClicked: (event) =>
    checkbox = $(event.target)
    label = checkbox.parent()
    localityNumber = checkbox.data('localityNumber')

    if checkbox.is(':checked')
      @createPlacement(localityNumber)
    else
      @destroyPlacement(localityNumber)

    true

  otherRegionAreasBtnClicked: (event) =>
    @otherRegionAreas.show()
    @otherRegionAreasBtn.parent().remove()

  otherRegionsBtnClicked: (event) =>
    @otherRegions.show()
    @otherRegionsBtn.parent().remove()

  renderRegionAreaBtnClicked: (event) =>
    btn = $(event.currentTarget)


    regionAreaId = btn.data('region-area-id')
    regionArea = @findRegionArea(regionAreaId)
    html = $ Sti.Helpers.renderTemplate 'general/post_posting/placements/cities',
      title: regionArea[0]['name'], cities: regionArea[1]

    btn.attr('disabled', 'disabled')

    @otherRegionAreas.before html
    @highlight(html, '#ffa')

  renderRegionBtnClicked: (event) =>
    btn = $(event.currentTarget)

    regionId = btn.data('region-id')
    region = @findRegion(regionId)
    html = $ Sti.Helpers.renderTemplate 'general/post_posting/placements/cities',
      title: region[0]['name'], cities: region[1]

    btn.attr('disabled', 'disabled')

    @otherRegions.before html
    @highlight(html, '#ffa')

  createPlacement: (localityNumber) =>
    data = locality_number: localityNumber

    $.ajax
      url: @createUrl(@postNumber)
      type: 'post'
      dataType: 'json'
      data: data
      success: @successfullyCreated
      error: @createFailed
      complete: @updatePlacementLimitsAfterComplete

    false

  destroyPlacement: (localityNumber) =>
    placementNumber = @findInputByLocalityNumber(localityNumber).data('placementNumber')

    if placementNumber
      url = @destroyUrl(placementNumber)
      $.ajax
        url: url
        type: 'delete'
        dataType: 'json'
        success: @successfullyDestroyed
        error: @destroyFailed
        complete: @updatePlacementLimitsAfterComplete
    else
      delay(1000, => @destroyPlacement(localityNumber))

    false

  successfullyCreated: (response) =>
    pl = response.placement
    checkbox = @findInputByLocalityNumber(pl.locality.number)
    label = checkbox.parent()
    checkbox.data("placementNumber", pl.number)
    @clearStateClasses(label)
    label.addClass("-#{pl.status_name}")

  createFailed: (xhr) =>
    if (xhr.status == 422)
      checkbox = @findInputByLocalityNumber(xhr.responseJSON.locality_number)
      checkbox.prop("checked", false);
    else
      alert 'Не удалось добавить публикацию. Попробуйте перезагрузить страницу и повторить снова'

  successfullyDestroyed: (response) =>
    pl = response.placement
    checkbox = @findInputByLocalityNumber(pl.locality.number)
    label = checkbox.parent()
    checkbox.data("placementNumber", pl.number)
    @clearStateClasses(label)

  destroyFailed: (xhr) =>
    alert('Не удалось снять публикацию. Попробуйте перезагрузить страницу и повторить снова')

  updatePlacementLimitsAfterComplete: (resp) =>
    if resp.responseJSON and resp.responseJSON.limits
      @limitedCheckboxes.set(resp.responseJSON.limits)

  clearStateClasses: (el) =>
    classList = el.attr('class').split(/\s+/)
    for className in classList
      if className.match(/^\-/)
        el.removeClass(className)

  findInputByLocalityNumber: (localityNumber) =>
    @el.find("[role=toggle-placement][data-locality-number=#{localityNumber}]")

  findRegion: (id) =>
    Sti.Data.postPlacements.regions.find (el) -> el[0]['id'] == id

  findRegionArea: (id) =>
    Sti.Data.postPlacements.region_areas.find (el) -> el[0]['id'] == id

  highlight: (el, color) =>
    unless Sti.Helpers.isElementInViewport(el[0])
      $('html, body').animate
        scrollTop: el.offset().top - 60
        500

    el.css('margin', '0 -10px')
    el.css('padding', '0 10px')
    el.animate backgroundColor: color, ->
      el.animate backgroundColor: 'transparent', ->
        el.css('margin', '')
        el.css('padding', '')
