export default function loadYmaps(callback) {
  loadScriptOnce('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then(() => {
    callback();
  }).catch(error => {
    console.error('Ymaps script loading failed:', error);
  })
}

let scriptLoadedPromise;
let isScriptLoaded = false;

function loadScriptOnce(url) {
  if (!scriptLoadedPromise) {
    scriptLoadedPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.onload = () => {
        isScriptLoaded = true;
        resolve();
      };
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
  return scriptLoadedPromise;
}
