import byPosition from './by_position';

export default function getRegionAreas(regionId) {
  const regionAreas = Sti.Data.regionAreas;

  if (!regionId) { return regionAreas }

  const regionIdInt = parseInt(regionId);
  const filteredRegionAreas = regionAreas.filter(area => area.region_id === regionIdInt);

  return filteredRegionAreas.sort(byPosition);
}

