import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form--appendable-suggestions"
export default class extends Controller {
  static values = { phrases: Array }
  static targets = ["input"]

  connect() {
    this.renderSuggestions()
  }

  renderSuggestions() {
    if (!this.hasInputTarget) { return }

    const block = document.createElement('p')
    block.classList.add('appendable-suggestions')

    for (let i = 0; i < this.phrasesValue.length; i++) {
      const btnLink = document.createElement('a')
      btnLink.href = '#'
      btnLink.classList.add('appendable-suggestion')
      btnLink.dataset.action = 'click->form--appendable-suggestions#appendAndMute'
      btnLink.innerText = this.phrasesValue[i]

      block.append(btnLink)
    }

    this.inputTarget.parentNode.append(block)
  }

  appendAndMute(e) {
    e.preventDefault()

    const link = e.target;
    this.append(link.innerText);
    this.mute(link);
  }

  append(text) {
    if (this.inputTarget.value.length) {
      this.inputTarget.value = this.inputTarget.value + ' '
    }
    this.inputTarget.value += text
  }

  mute(link) {
    link.classList.add('-mutted')
  }
}
