import VanilaHelpers from './helpers.js'

window.delay = (ms, func) -> setTimeout func, ms
window.console ||= { log: -> false }

Helpers =
  authenticityToken: VanilaHelpers.authenticityToken
  byPosition: VanilaHelpers.byPosition

  russianPluralize: (count, one, few, many) ->
    many or= few
    if (count % 10 == 1 && count % 100 != 11 ) then one
    else if ( count % 10 >= 2 && count % 10 <= 4 && ( count % 100 < 10 || count % 100 >= 20)) then few
    else many

  optionTag: (value, content) ->
    if value? && content?
      "<option value='#{value}'>#{content}</option>"
    else if value? && !content?
      "<option value='#{value}'>#{value}</option>"
    else
      "<option>#{content}</option>"

  regionsByFederalDistrictId: (federalDistrictId) ->
    regions = jQuery.grep Sti.Data.regions, (region) ->
      region.federal_district_id == parseInt federalDistrictId
    regions.sort @byPosition

  importantCitiesByRegionId: (regionId) ->
    regionId = parseInt regionId
    cities = jQuery.grep Sti.Data.importantCities, (city) ->
      city.region_id == regionId
    cities.sort @byPosition

  regionAreasByRegionId: (regionId) ->
    regionId = parseInt regionId
    regionAreas = jQuery.grep Sti.Data.regionAreas, (area) ->
      area.region_id == regionId
    regionAreas.sort(@byPosition)

  regionBySubdomain: VanilaHelpers.regionBySubdomain

  flashMessage: (message, type, options) ->
    flashTypesMap = Sti.Data.flashTypesMap
    flashMessage = $('<div class="alert">').append(message)
    unless options && options.closeButton == false
      flashMessage.append $("<button class='close' type='button' data-dismiss='alert'>&times;</button>")
    flashMessage.addClass('alert-' + flashTypesMap[type]) if type? && flashTypesMap[type]
    flashMessage

  flash: (message, type) ->
    flashMessage = @flashMessage(message, type).hide()
    $('#flash_messages').append flashMessage
    flashMessage.fadeIn()

  spinnerSettings: (presetName, settings={}) ->
    unless typeof(presetName) == 'string'
      settings = presetName
      presetName = 'default'
    preset = Sti.Data.SpinnerSettings[presetName]
    $.extend {}, preset, settings

  extendSpinSettings: (settings) ->
    console.log('Sti.Helpers.extendSpinSettings is depricated, use Sti.Helpers.spinnerSettings instead')
    @spinnerSettings('default', settings)

  toggleTip: (tipId) ->
    $.ajax
      url: "/tips/#{tipId}/toggle"
      type: 'patch'
      dataType: 'json'
      success: (response) ->
        # response

  isMobileVersion: VanilaHelpers.isMobileVersion

  currentRegion: VanilaHelpers.currentRegion

  changeUrlSubdomain: (url, subdomain) ->
    r = Sti.Data.urlRegExpParts
    match = url.match(new RegExp "(#{r.schema})(#{r.hostname})(#{r.port})?(#{r.path})?(#{r.query})?")

    schema = match[1]
    hostname = match[2]
    port = match[3] || ''
    path = match[4] || ''
    query = match[5] || ''

    hostnameParts = hostname.split('.')
    if hostnameParts.length == 3
      hostnameParts[0] = subdomain
    else
      hostnameParts = [subdomain].concat(hostnameParts)
    hostname = hostnameParts.join('.')

    # remove city slug
    if Sti.currentCity
      path = path.replace("/#{Sti.currentCity.slug}", '')

    # remove post number
    if match = path.match(/(.*)\/([tabsrp][0-9\-]+)$/)
      path = match[1]

    schema + hostname + port + path

  urlToPath: (url) ->
    r = Sti.Data.urlRegExpParts
    hostParts = new RegExp "#{r.schema}(#{r.hostname})(#{r.port})?"
    url.replace hostParts, ''

  signInOrSignUp: (afterSignUpUrl, afterSignInCallback) ->
    if Sti.currentUser?
      afterSignInCallback()
    else
      dialog = new Sti.AreYouRegisteredDialog(afterSignUpUrl, afterSignInCallback)
      dialog.show()

  # loadYmaps: (callback) ->
  #   if document.ymaps
  #     callback()
  #   else
  #     filenode = document.createElement 'script'
  #     filenode.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU'
  #     # IE
  #     filenode.onreadystatechange = ->
  #       if filenode.readyState == 'loaded' or filenode.readyState == 'complete'
  #         filenode.onreadystatechange = null
  #         callback()
  #     # others
  #     filenode.onload = callback
  #     document.head.appendChild(filenode)

  scrollTo: (element) =>
    $('html, body').animate
      scrollTop: element.offset().top - 60
      500

  simpleHash: (s) ->
    hash = 0
    if s.length == 0
      return hash
    i = 0
    l = s.length
    while i < l
      char = s.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash |= 0
      # Convert to 32bit integer
      i++
    hash

  numberToCurrency: VanilaHelpers.numberToCurrency

  disableBackButton: (message) =>
    history.pushState(null, document.title, location.href)
    window.addEventListener 'popstate', (event) =>
      alert(message)
      history.pushState(null, document.title, location.href);

  removeClass: (obj, cls) =>
    classes = if obj.className
      obj.className.split(' ')
    else
      []

    for currentCls, i in classes
      if currentCls == cls
        classes.splice(i, 1)

    obj.className = classes.join(' ')

  arrayInColumns: (arr, n) =>
    result = []
    base = Math.ceil(arr.length / n)
    for el, i in arr
      k = Math.floor(i / base)
      result[k] ||= []
      result[k].push(el)
    result

  addClass: (obj, cls) =>
    classes = if obj.className
      obj.className.split(' ')
    else
      []
    for currentCls, i in classes
      if classes[i] is cls
        return

    classes.push(cls)
    obj.className = classes.join(' ')

  # enRuTranscribeTable: {"Q":"Й","W":"Ц","E":"У","R":"К","T":"Е","Y":"Н","U":"Г","I":"Ш","O":"Щ","P":"З","[":"х","]":"ъ","A":"Ф","S":"Ы","D":"В","F":"А","G":"П","H":"Р","J":"О","K":"Л","L":"Д",";":"ж","'":"э","Z":"Я","X":"Ч","C":"С","V":"М","B":"И","N":"Т","M":"Ь",",":"б",".":"ю","/":".","q":"й","w":"ц","e":"у","r":"к","t":"е","y":"н","u":"г","i":"ш","o":"щ","p":"з","a":"ф","s":"ы","d":"в","f":"а","g":"п","h":"р","j":"о","k":"л","l":"д","z":"я","x":"ч","c":"с","v":"м","b":"и","n":"т","m":"ь"}
  # enRuTranscribe: (str) ->
  #   if str and str.length
  #     $.map(str.split(''), (v) => @enRuTranscribeTable[v] or v ).join('')
  #   else
  #     ''

  isElementInViewport: VanilaHelpers.isElementInViewport
  renderTemplate: VanilaHelpers.renderTemplate
  renderTemplateHTML: VanilaHelpers.renderTemplateHTML
  htmlToElems: VanilaHelpers.htmlToElems

window.Sti or= {}
window.Sti.Helpers or= {}
jQuery.extend window.Sti.Helpers, Helpers
