import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["category"];

  connect() { }

  changeSubsection(event) {
    this.categoryTarget.innerHTML = '';
  }
}
