import on from "./lib/on";
import sorting from "./lib/sorting.js";
import fileUpload from "./lib/file_upload.js";

if (!window.Sti) { window.Sti = {} };
if (!window.Sti.Lib) { window.Sti.Lib = {} };

Sti.Lib.on = on;
Sti.Lib.sorting = sorting;
Sti.Lib.fileUpload = fileUpload;
