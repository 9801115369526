import AppendableSuggestions from '../../general/fields/appendable_suggestions'
import ShowMoreItemsComponent from '../../shared/show_more_items_component'

export default class RepairForm {
  formAttrName = 'repair';

  repairCategoryMessages = {
    info: (limit) => `Максимальное число областей ремонта, которое можно выбрать — ${limit.overall}, уже добавили — ${limit.reached}.`,
    hitLimit: (limit) => `Выбрано максимальное количество областей ремонта — ${limit.overall}.`
  }
  techCategoryMessages = {
    info: (limit) => `Максимальное число видов техники, которое можно выбрать — ${limit.overall} видов техники, уже добавили — ${limit.reached}.`,
    hitLimit: (limit) => `Выбрано максимальное количество видов техники — ${limit.overall}.`
  }

  constructor(el) {
    this.el = el;

    new ShowMoreItemsComponent(this.el, { comma: false })

    new Sti.PhotoFields({
      container: this.el.querySelector('.photo-fields'),
      imageableName: this.formAttrName,
      photoType: 'tech_photo',
    });

    new Sti.PhonesFields(this.el.querySelector('.profile-form-phones'));

    const repairCategoriesEl = this.el.querySelector('#repair_categories');
    const limitedCheckboxesRC = new Sti.LimitedCheckboxes(repairCategoriesEl, this.repairCategoryMessages)
    limitedCheckboxesRC.checkLimitsOnChange('[name="repair[repair_category_ids][]"]')

    const techCategoriesEl = this.el.querySelector('#tech_categories')
    const limitedCheckboxesTC = new Sti.LimitedCheckboxes(techCategoriesEl, this.techCategoryMessages)
    limitedCheckboxesTC.checkLimitsOnChange('[name="repair[tech_category_ids][]"]')

    const descriptionInput = this.el.querySelector('[name$="[description]"]')
    new Sti.LimitedLengthField(descriptionInput);
    new AppendableSuggestions(descriptionInput);
  }
}


