import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form--category"
export default class extends Controller {
  static outlets = ["bid--eav-values"]
  static values = { 'urlPath': String }

  connect() {
  }

  changed(event) {
    const value = event.target.value;
    if (value && value.length) {
      const url = this.urlPathValue + '/' + value;

      fetch(url)
        .then((r) => r.json())
        .then((json) => { this.updated(json) })
    } else {
      this.reset();
    }
  }

  reset() {
    this.bidEavValuesOutlet.reset();
  }

  updated(categoryJson) {
    this.bidEavValuesOutlet.render(categoryJson.eav_attrs);
  }
}
