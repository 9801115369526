import AppendableSuggestions from '../../general/fields/appendable_suggestions'
import ShowMoreItemsComponent from '../../shared/show_more_items_component'

export default class BidForm {
  formAttrName = 'bid';

  constructor(el) {
    console.log('old BidForm')
    this.el = el;

    new ShowMoreItemsComponent(this.el);

    new Sti.PhonesFields(this.el.querySelector('.profile-form-phones'));

    const descriptionInput = this.el.querySelector('[name$="[description]"]');
    new Sti.LimitedLengthField(descriptionInput);

    this.category = this.el.querySelector('[name$="[category_id]"]');

    this.eavValues = new Sti.EavValuesFields(this);

    this.category = this.el.querySelector('[name$="[category_id]"]');

    const durationInput = this.el.querySelector('[name$="[duration]"]')
    new AppendableSuggestions(durationInput);

    this.initEvents();
  }

  initEvents() {
    this.category.addEventListener('change', (e) => { this.updateTechCategory(e) });
  }

  updateTechCategory(event) {
    const value = event.target.value;
    if (value && value.length) {
      const url = '/tech_categories/' + value;

      fetch(url)
        .then((r) => r.json())
        .then((json) => { this.categoryUpdated(json) })
    } else {
      this.categoryReset();
    }
  }

  categoryReset() {
    this.eavValues.reset();
  }

  categoryUpdated(categoryJson) {
    this.eavValues.categoryUpdated(categoryJson);
  }
}
