module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"placements-block\">\n  <h5 class=\"title\"> ")
    ; __append(escapeFn( title ))
    ; __append(" </h5>\n  <div class=\"placements-container\">\n    ")
    ;  Sti.Helpers.arrayInColumns(cities, 4).forEach(function(cities) { 
    ; __append("\n      <div class=\"placements-column\">\n        ")
    ;  cities.forEach(function(locality_and_placement) {
    ; __append("\n          ")
    ;  locality = locality_and_placement[0] 
    ; __append("\n          ")
    ;  placement = locality_and_placement[1] 
    ; __append("\n          <label\n            class=\"\n              locality-checkbox\n              ")
    ;  if (placement) { 
    ; __append(" ")
    ; __append(escapeFn( '-' + placement['status_name'] ))
    ; __append(" ")
    ;  } 
    ; __append("\">\n            <input\n              data-locality-number=\"")
    ; __append(escapeFn( locality.number ))
    ; __append("\"\n              ")
    ;  if (placement) { 
    ; __append(" checked ")
    ;  } 
    ; __append("\n              role=\"toggle-placement\" type=\"checkbox\">\n              ")
    ; __append(escapeFn( locality.name))
    ; __append("\n          </label>\n        ")
    ;  }) 
    ; __append("\n      </div>\n    ")
    ;  }) 
    ; __append("\n  </div>\n</div>\n")
  }
  return __output;

}