import { Controller } from "@hotwired/stimulus"
import maskPhone from '../../src/shared/helpers/mask_phone';

// Connects to data-controller="form--anonymous-phones"
export default class extends Controller {
  static targets = ["addButton"]
  static values = { max: Number }
  static outlets = ["form--anonymous-phone"]

  connect() {
  }

  formAnonymousPhoneOutletConnected(outlet, element) {
    this.checkAddBtnPresence();
  }

  formAnonymousPhoneOutletDisconnected(outlet, element) {
    this.checkAddBtnPresence();
  }

  add(e) {
    e.preventDefault()

    const lastNewPhoneElement = this.element.querySelector('.form-anonymous-phone:last-child');
    const currentNewPhoneElement = this.cloneAndResetNewPhoneElement(lastNewPhoneElement);
    lastNewPhoneElement.after(currentNewPhoneElement);
  }

  cloneAndResetNewPhoneElement(phoneElement) {
    const currentNewPhoneElement = phoneElement.cloneNode(true);
    currentNewPhoneElement.querySelectorAll('[name*="[phones_attributes]"').forEach((input) => {
      const tempId = new Date().getTime();
      input.value = '';

      input.name = input.name.replace(/\d+/, tempId);
      input.id = input.id.replace(/\d+/, tempId);
    });

    return currentNewPhoneElement;
  }

  checkAddBtnPresence() {
    if (!this.hasAddButtonTarget) return;

    if (this.element.querySelectorAll('.form-anonymous-phone').length >= (this.maxValue + 1)) {
      this.addButtonTarget.classList.add('-hidden');
    } else {
      this.addButtonTarget.classList.remove('-hidden');
    }
  }
}
