class Sti.SpareCategoriesFields
  CATEGORIES_SELECTOR: '[name$="[spare_category_ids][]"]'

  constructor: (@form) ->
    @container = @form.find '.spare-categories-fields'
    @row = @container.find '.spare-categories-row'
    @selectAll = @container.find '.spare-categories-select-all'
    @selectAllInput = @selectAll.find 'input'
    @binds()

  binds: ->
    @selectAllInput.on 'change', @selectAllInputChanged
    @form.on 'change', @CATEGORIES_SELECTOR, @categoryCheckboxChanged

  selectAllInputChanged: (event) =>
    if $(event.target).is ':checked'
      $(@CATEGORIES_SELECTOR).prop 'checked', true
      @disableSelectAll()

  categoryCheckboxChanged: (event) =>
    if @form.find(@CATEGORIES_SELECTOR + ':not(:checked)').length
      @selectAllInput.prop 'checked', false
      @enableSelectAll()
    else
      @selectAllInput.prop 'checked', true
      @disableSelectAll()

  reset: => @row.children().remove()
  hide: => @container.hide()
  show: => @container.show()

  disableSelectAll: ->
    @selectAllInput.prop 'checked', true
    @selectAllInput.prop 'disabled', true
    @selectAll.addClass 'disabled'

  enableSelectAll: ->
    @selectAllInput.prop 'checked', false
    @selectAllInput.prop 'disabled', false
    @selectAll.removeClass 'disabled'

  update: (categories) ->
    @enableSelectAll()

    middleCeil = Math.ceil(categories.length / 2)
    column1 = categories[0..(middleCeil - 1)]
    column2 = categories[middleCeil..-1]

    @row.html(@renderColumn column1)
    @row.append(@renderColumn column2)

  renderColumn: (categories) =>
    html = "<div class='span6'>"
    for category in categories
      html += """
        <label class='checkbox'>
          <input name='spare[spare_category_ids][]' type='checkbox' value='#{category.id}'>
          #{category.title}
        </label>\n
      """
    html + "</div>"
