import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { id: Number, status: String, isIncoming: Boolean, version: Number }
  static targets = []
  static outlets = ["admin--chat-account"]
  static observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };
  static readThreshold = 2000;

  connect() {
    if (this.isIncomingValue && this.statusValue !== 'read') {
      this.observer = this.initiateObserver()
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  initiateObserver() {
    const observer = new IntersectionObserver(
      (entries) => { this.handleIntersection(entries) },
      this.constructor.observerOptions);
    observer.observe(this.element);

    return observer;
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting && document.visibilityState === 'visible') {
        this.readTimeout = this.startReadTimeout();
      } else {
        clearTimeout(this.readTimeout);
      }
    })
  }

  startReadTimeout() {
    setTimeout(
      () => { this.updateStatusAsRead() },
      this.constructor.readThreshold
    )
  }

  replace(data) {
    if (this.idValue !== data.message.id) { return }
    if (this.idVersion >= data.message.version) { return }

    this.element.insertAdjacentHTML('afterend', data.html);
    this.element.remove();
  }

  updateStatusAsRead() {
    const subscription = this.adminChatAccountOutlet.subscription;
    subscription.updateStatus(this.idValue, 'read')
  }
}
