// The follow line magicly fix bootstrap-jquery loading
$.fn // keep it here

require("bootstrap-2.3.2/js/bootstrap-transition")
require("bootstrap-2.3.2/js/bootstrap-alert")
require("bootstrap-2.3.2/js/bootstrap-modal")
require("bootstrap-2.3.2/js/bootstrap-dropdown")
require("bootstrap-2.3.2/js/bootstrap-scrollspy")
require("bootstrap-2.3.2/js/bootstrap-tab")
require("bootstrap-2.3.2/js/bootstrap-tooltip")
require("bootstrap-2.3.2/js/bootstrap-popover")
require("bootstrap-2.3.2/js/bootstrap-button")
require("bootstrap-2.3.2/js/bootstrap-collapse")
// require("bootstrap-2.3.2/js/bootstrap-carousel")
require("bootstrap-2.3.2/js/bootstrap-typeahead")
require("bootstrap-2.3.2/js/bootstrap-affix")
