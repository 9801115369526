jQuery ->
  # if !(Sti.env is 'development') and $('#side_banners').length

  if !(Sti.env is 'development') and $('#side_banners').length and (Sti.isCurrentPageContentPage || Sti.isCurrentPageCabinetPage)
    $.ajax
      url: '/side_banners'
      success: (html) ->
        if Sti.Helpers.isMobileVersion()
          $('#mobile_side_banners').html html
          $('.side-banners').each (i, group)->
            new Sti.SideBannersGroup $(group)
        else
          $('#side_banners').html html

class Sti.SideBannersGroup
  constructor: (@container) ->
    @slides = @container.find '.side-banner'
    @dots = @container.find '.dot'

    @imagesLoadedCounter = 0
    @images = @container.find 'img'

    @bindings()

  bindings: ->
    @dots.on 'click', @dotClicked
    @images.on 'load', @imageLoaded

  dotClicked: (e) =>
    e.preventDefault()
    currentDot = $(e.target)
    @dots.removeClass 'active'
    currentDot.addClass 'active'

    currentSlide = $(currentDot.attr 'href')
    @slides.hide()
    currentSlide.show()

  imageLoaded: =>
    @imagesLoadedCounter++
    if @imagesLoadedCounter == @images.length
      # Render img start       Render complete
      requestAnimationFrame => requestAnimationFrame =>
        @slides.height @maxHeight(@slides)

  maxHeight: (slides)=>
    Math.max.apply Math, slides.map((i, slide) -> $(slide).height())
