import fetchWithCsrf from '../../shared/helpers/fetch_json_with_csrf';

class PostContacts {
  constructor(el, placementNumber) {
    this.el = el;
    this.placementNumber = placementNumber
    this.revealContactBtn = el.querySelector('[role="show_contact"]');

    this.phoneLink = this.el.querySelector('[href^="tel:"]')
    this.whatsappLink = this.el.querySelector('[href*="wa.me/"]')

    this.binds();
  }

  binds() {
    if (this.revealContactBtn) {
      this.revealContactBtn.addEventListener('click', (e) => this.revealContact(e))
    }

    if (this.phoneLink) {
      this.phoneLink.addEventListener('click', () => this.saveContactView('phone_link'))
    }

    if (this.whatsappLink) {
      this.whatsappLink.addEventListener('click', () => this.saveContactView('whatsapp_link'))
    }
  }

  revealContact(e) {
    e.preventDefault()
    this.el.classList.remove('-hidden')

    this.saveContactView('show_contact_button')
  }

  saveContactView(source) {
    const data = {
      contact_view: {
        placement_number: this.placementNumber,
        url: location.href,
        source: source
      }
    }

    fetchWithCsrf('/contact_views', { method: 'POST', body: JSON.stringify(data) })
      .then(response => {
        // console.log(response);
      })
  }
}

export default PostContacts;
