require('./general/auth.js');
require('./general/catalogs_legacy.coffee');
require('./general/catalogs.js');
require('./general/post.js');
require('./general/fields.js');
require('./general/post_posting.js');
require('./general/profile.js');
require('./general/cabinet.js');

require('./general/test_autocomplete.js');
