import Autocomplete from '../shared/autocomplete'

document.addEventListener("DOMContentLoaded", function() {
  const regionInput = document.querySelector('#test_region_autocomplete');
  if (regionInput) { new Autocomplete(regionInput) }

  const cityInput = document.querySelector('#test_city_autocomplete');
  if (cityInput) { new Autocomplete(cityInput) }
});

