module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"comment-item ")
    ;  if (hidden) { 
    ; __append("-hidden")
    ;  } 
    ; __append("\">\n  <div class=\"meta comment-meta\">\n    <div class=\"author\">\n      ")
    ;  if (comment.author) { 
    ; __append("\n        <a href=\"/admin/users/")
    ; __append(escapeFn( comment.author.id ))
    ; __append("\">")
    ; __append(escapeFn( comment.author.title ))
    ; __append("</a>\n      ")
    ;  } else { 
    ; __append("\n        Robot\n      ")
    ;  } 
    ; __append("\n    </div>\n    <div class=\"time\">")
    ; __append(escapeFn( comment.created_at_formatted ))
    ; __append("</div>\n  </div>\n  <div class=\"content\">")
    ; __append(escapeFn( comment.content ))
    ; __append("</div>\n  <div class=\"actions\">\n    ")
    ;  if (comment.can_update) { 
    ; __append("\n      <i class=\"icon-pencil\" role=\"edit\"></i>\n    ")
    ;  } 
    ; __append("\n  </div>\n  <form url=\"/admin/comments/")
    ; __append(escapeFn( comment.id ))
    ; __append("\" class=\"comment-form\" role='edit_comment_form'>\n    <textarea id=\"comment_content\" rows=\"3\">")
    ; __append(escapeFn( comment.content ))
    ; __append("</textarea>\n    <div class=\"error\" id=\"comment_error\"></div>\n    <p class=\"actions\">\n      <button class=\"btn btn-primary btn-small\" type=\"submit\">Сохранить</button>\n    </p>\n  </form>\n</div>\n")
  }
  return __output;

}