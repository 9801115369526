// require 'lib/events'
import renderTemplate from '../../shared/helpers/render_template';

if (!window.Sti) { window.Sti = {} };

Sti.EavValuesFields = function (form) {
  const that = this

  this.form = form
  this.name = form.ATTR_NAME || form.formAttrName
  this.routes = {
    category: {
      tech: '/tech_categories/',
      bid: '/tech_categories/',
      sell: '/sell_categories/',
      service: '/service_categories/'
    },
    title_seo_prefixes: '/title_seo_prefixes'
  }

  this.el = form.el.querySelector('.eav-values');
  setInputs.call(this)

  this.categoryUpdated = function (category, onlyImportant = false) {
    const eavAttrs = onlyImportant ? category.eav_attrs.filter((a) => a.important) : category.eav_attrs;

    render(eavAttrs);
    setInputs.call(that);
  }

  function setInputs() {
    this.inputs = this.el.querySelectorAll('input[type=number],input[type=checkbox],select');
  }

  function binds() {
    if (that.name === 'tech' || that.name === 'service') {
      Sti.Lib.on('input,select', 'change', updateTitleSeoPrefixes, that.el);
    }
    Sti.Lib.on('[role="show_more_eav_attrs"]', 'click', showMoreEavAttrs, that.el);
  }

  function showMoreEavAttrs(e) {
    e.preventDefault();

    const container = e.target.parentNode;
    container.querySelectorAll('.-hidden').forEach((el) => { el.classList.remove('-hidden') });
    e.target.remove();
  }

  function reset() {
    that.el.innerHTML = '';
  }

  function render(eavAttrs) {
    reset();

    let html = renderFields(eavAttrs);

    if (that.name === 'bid') {
      html += ' <button class="show-more btn btn-link" role="show_more_eav_attrs">Еще параметры</button> '
    }
    that.el.innerHTML = html;
  }

  function rendereField(attrs, i) {
    const attr = attrs[i];
    const templateName = "general/eav_value_fields/" + attr.attr_type;
    const namePrefix = that.name + '[eav_values_attributes]' + '[' + i + ']';

    return renderTemplate(templateName, { attr: attr, namePrefix: namePrefix });
  }

  function renderFields(attrs) {
    let html = '';
    for (var i = 0; i < attrs.length; i++) {
      html += rendereField(attrs, i)
    }

    return html;
  }

  // function renderBidEavValues(eavAttrs) {
  //   renderBidEavValues
  // }

  function updateTitleSeoPrefixes() {
    var data = {
      post_type: that.name,
      category_id: (form.category.val ? form.category.val() : form.category.value),
      eav_values: parseEavValues()
    };

    $.ajax({
      url: that.routes.title_seo_prefixes,
      data: data,
      type: 'get',
      dataType: 'json',
      success: updateTitleSeoPrefixFields
    })
  }

  function parseEavValues() {
    var eavValuesData = {};

    var attrIdInputs = that.el.querySelectorAll('[name$="[attr_id]"]');
    for (var i = 0; i < attrIdInputs.length; i++) {
      var namePrefix = attrIdInputs[i].name.replace('[attr_id]', '');
      var attrId = attrIdInputs[i].value;
      var attrTypeInput = that.el.querySelector('[name="' + namePrefix + '[type]"]')

      if (attrTypeInput.value === 'Eav::Value::Multiple') {
        var valueInputs = document.querySelectorAll('[name="' + namePrefix + '[value][]"]');
        var value = [];
        for (var k = 0; k < valueInputs.length; k++) {
          var input = valueInputs[k];
          if (input.checked) { value.push(input.value) }
        }
      } else {
        var valueInput = document.querySelector('[name="' + namePrefix + '[value]"]');
        var value = valueInput.value;
      }

      if (value.length) {
        eavValuesData[attrId] = value;
      };
    }

    return eavValuesData;
  }

  function updateTitleSeoPrefixFields(result) {
    var prefixes = result.title_seo_prefixes;
    if (form.titleSeoPrefixFields) {
      form.titleSeoPrefixFields.updatePrefixes(prefixes);
    }
  }

  binds()
}

export default Sti.EavValuesFields
