module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class='modal' tabindex='-1'>\n  <div class='modal-body'>\n    <p>")
    ; __append(escapeFn( message ))
    ; __append("</p>\n    <div class=\"container-fluid\">\n      <div class=\"row-fluid\">\n        <div class=\"span6\">\n          <a href='/user/all' class=\"btn btn-success btn-block\">\n            Переход в личный кабинет\n          </a>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n")
  }
  return __output;

}